import * as constant from './constants'

// GET_All_ORDERS
export function getAllOrders(payload) {
  return {
    type: constant.GET_All_ORDERS,
    payload,
  }
}

export function getAllOrdersSuccess(payload) {
  return {
    type: constant.GET_All_ORDERS_SUCCESS,
    payload,
  }
}

export function getAllOrdersFailed(error) {
  return {
    type: constant.GET_All_ORDERS_FAILED,
    error,
  }
}

// GET_CURRENT_ORDERS
export function getCurrentOrders(payload) {
  return {
    type: constant.GET_CURRENT_ORDERS,
    payload,
  }
}

export function getCurrentOrdersSuccess(payload) {
  return {
    type: constant.GET_CURRENT_ORDERS_SUCCESS,
    payload,
  }
}

export function getCurrentOrdersFailed(error) {
  return {
    type: constant.GET_CURRENT_ORDERS_FAILED,
    error,
  }
}

// CREATE_ADDRESS
export function createAddress(address, isPickup) {
  return {
    type: constant.CREATE_ADDRESS,
    address,
    isPickup,
  }
}

export function createAddressSuccess(payload) {
  return {
    type: constant.CREATE_ADDRESS_SUCCESS,
    payload,
  }
}

export function createAddressFailed(error) {
  return {
    type: constant.CREATE_ADDRESS_FAILED,
    error,
  }
}

export function clearStatusEditAbleAddress() {
  return {
    type: constant.CLEAR_STATUS_EDITABLE_ADDRESS,
  }
}

// UPDATE_ADDRESS
export function updateAddress(address, isPickup) {
  return {
    type: constant.UPDATE_ADDRESS,
    address,
    isPickup,
  }
}

export function updateAddressSuccess(payload) {
  return {
    type: constant.UPDATE_ADDRESS_SUCCESS,
    payload,
  }
}

export function updateAddressFailed(error) {
  return {
    type: constant.UPDATE_ADDRESS_FAILED,
    error,
  }
}

export function updateUserInfo(
  userInfo,
  isChangeEmail,
  isChangePassword,
  emailPayload,
  passwordPayload
) {
  return {
    type: constant.UPDATE_USER_INFO,
    userInfo,
    isChangeEmail,
    isChangePassword,
    emailPayload,
    passwordPayload,
  }
}

export function updateUserInfoSuccess(payload) {
  return {
    type: constant.UPDATE_USER_INFO_SUCCESS,
    payload,
  }
}

export function updateUserInfoFailed(error) {
  return {
    type: constant.UPDATE_USER_INFO_FAILED,
    error,
  }
}

export function updateEmail(payload) {
  return {
    type: constant.UPDATE_EMAIL,
    payload,
  }
}

export function updateEmailSuccess(payload) {
  return {
    type: constant.UPDATE_EMAIL_SUCCESS,
    payload,
  }
}

export function updateEmailFailed(error) {
  return {
    type: constant.UPDATE_EMAIL_FAILED,
    error,
  }
}

export function updatePassword(payload) {
  return {
    type: constant.UPDATE_PASSWORD,
    payload,
  }
}

export function updatePasswordSuccess(payload) {
  return {
    type: constant.UPDATE_PASSWORD_SUCCESS,
    payload,
  }
}

export function updatePasswordFailed(error) {
  return {
    type: constant.UPDATE_PASSWORD_FAILED,
    error,
  }
}

// GET PRICE SUBSCRIPTIONS
export function getPriceSubscriptions(payload) {
  return {
    type: constant.GET_PRICE_SUBSCRIPTIONS,
    payload,
  }
}
export function getPriceSubscriptionsSuccess(payload) {
  return {
    type: constant.GET_PRICE_SUBSCRIPTIONS_SUCCESS,
    payload,
  }
}
export function getPriceSubscriptionsFailed(payload) {
  return {
    type: constant.GET_PRICE_SUBSCRIPTIONS_FAILED,
    payload,
  }
}

// DELETE PRICE SUBSCRIPTIONS
export function deletePriceSubscriptions(payload) {
  return {
    type: constant.DELETE_PRICE_SUBSCRIPTIONS,
    payload,
  }
}
export function deletePriceSubscriptionsSuccess(payload) {
  return {
    type: constant.DELETE_PRICE_SUBSCRIPTIONS_SUCCESS,
    payload,
  }
}
export function deletePriceSubscriptionsFailed(payload) {
  return {
    type: constant.DELETE_PRICE_SUBSCRIPTIONS_FAILED,
    payload,
  }
}

// GET STOCK SUBSCRIPTIONS
export function getStockSubscriptions(payload) {
  return {
    type: constant.GET_STOCK_SUBSCRIPTIONS,
    payload,
  }
}
export function getStockSubscriptionsSuccess(payload) {
  return {
    type: constant.GET_STOCK_SUBSCRIPTIONS_SUCCESS,
    payload,
  }
}
export function getStockSubscriptionsFailed(payload) {
  return {
    type: constant.GET_STOCK_SUBSCRIPTIONS_FAILED,
    payload,
  }
}

// DELETE STOCK SUBSCRIPTIONS
export function deleteStockSubscriptions(payload) {
  return {
    type: constant.DELETE_STOCK_SUBSCRIPTIONS,
    payload,
  }
}
export function deleteStockSubscriptionsSuccess(payload) {
  return {
    type: constant.DELETE_STOCK_SUBSCRIPTIONS_SUCCESS,
    payload,
  }
}
export function deleteStockSubscriptionsFailed(payload) {
  return {
    type: constant.DELETE_STOCK_SUBSCRIPTIONS_FAILED,
    payload,
  }
}

// PRODUCT REVIEW
export function loadProductReview(payload) {
  return {
    type: constant.LOAD_PRODUCT_REVIEW,
    payload,
  }
}
export function saveProductReview(payload) {
  return {
    type: constant.SAVE_PRODUCT_REVIEW,
    payload,
  }
}

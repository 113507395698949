import * as constants from './constants'
import { call, put, takeLatest, delay } from 'redux-saga/effects'
import * as SiteActions from './actions'
import { history } from 'configureStore'
import * as categoryAction from 'containers/Category/actions'
import { get, has } from 'lodash'
import URL from 'constant/urls'
import config from 'global-config'
const { apiUrl } = config
function* onLoadBreadCumb(action) {
  let pathName = get(action, 'pathName', '')
  pathName = pathName.indexOf('/') === 0 ? pathName.substring(1) : pathName
  pathName =
    pathName.lastIndexOf('/') === pathName.length - 1
      ? pathName.substring(0, pathName.length - 1)
      : pathName
  if (!pathName) return
  let requestURL = apiUrl + '/find-slug/' + encodeURIComponent(pathName)
  try {
    const res = yield call(fetch, requestURL, {
      method: 'GET',
    })
    const data = yield res.json()
    const pathNameSlug = `/${data.slug}`
    if (data.type === 'cms-page' && pathNameSlug !== window.location.pathname) {
      yield call(history.push, pathNameSlug)
    } else if (
      action.isCategory &&
      has(data, 'type') &&
      has(data, 'id') &&
      !data.type &&
      !data.id
    ) {
      yield call(history.push, URL.notFound)
    } else {
      if (pathNameSlug !== window.location.pathname) {
        yield call(history.push, pathNameSlug)
        yield delay(1000)
      }
      yield put(SiteActions.saveBreadcumb(data))
      yield put(categoryAction.saveSlugData(data))
    }
  } catch (err) {
  } finally {
  }
}
export default function* expSaga() {
  yield takeLatest(constants.LOAD_CATEGORY_NAME, onLoadBreadCumb)
}

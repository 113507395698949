import * as constants from './constants'
import produce from 'immer'

export const initialState = {
  collectible: {
    generic: {
      items: {},
      hasMoreItems: false,
    },
    specific: {
      items: {},
      hasMoreItems: false,
    },
  },
  claimed: {
    generic: {
      items: {},
      hasMoreItems: false,
    },
    specific: {
      items: {},
      hasMoreItems: false,
    },
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // GET COLLECTIBLE VOUCHERS
      case constants.GET_VOUCHERS_COLLECTIBLE:
        break
      case constants.GET_VOUCHERS_COLLECTIBLE_SUCCESS:
        newState.collectible[action.payload.coupon_category].items[
          action.payload.assigned_page
        ] = action.payload.items
        newState.collectible[action.payload.coupon_category].hasMoreItems =
          action.payload.hasMoreItems
        break
      case constants.GET_VOUCHERS_COLLECTIBLE_FAILED:
        break

      // GET CLAIMED VOUCHERS
      case constants.GET_VOUCHERS_CLAIMED:
        break
      case constants.GET_VOUCHERS_CLAIMED_SUCCESS:
        newState.claimed[action.payload.coupon_category].items[
          action.payload.assigned_page
        ] = action.payload.items
        newState.claimed[action.payload.coupon_category].hasMoreItems =
          action.payload.hasMoreItems
        break
      case constants.GET_VOUCHERS_CLAIMED_FAILED:
        break

      // SAVE CLAIMED VOUCHERS
      case constants.SAVE_CLAIM_VOUCHER:
        break
      case constants.SAVE_CLAIM_VOUCHER_SUCCESS:
        if (
          newState.claimed[action.payload.coupon_category].items[
            action.payload.item.assigned_page
          ]
        ) {
          newState.claimed[action.payload.coupon_category].items[
            action.payload.item.assigned_page
          ].push(action.payload.item)
        }

        newState.collectible[action.payload.coupon_category].items[
          action.payload.item.assigned_page
        ].find(
          (v) => v.code.toLowerCase() === action.payload.item.code.toLowerCase()
        ).is_claimed = action.payload.item.is_claimed
        break

      case constants.SAVE_CLAIM_VOUCHER_FAILED:
        break

      case constants.SET_VOUCHER_IS_PROCESSING:
        newState[action.payload.voucher_type][
          action.payload.coupon_category
        ].items[action.payload.assigned_page].find(
          (v) => v.code.toLowerCase() === action.payload.code.toLowerCase()
        ).is_processing = action.payload.is_processing
        break

      default:
        break
    }
  })

export default reducer

import { isIOS, isAndroid } from 'react-device-detect'
import { get, filter, isEmpty, cloneDeep, replace } from 'lodash'
import dayjs from 'dayjs'
import config from 'global-config'
import { getAccessToken, getStoreCode } from './request'
import { CAPITAL_CARE_VENDOR, PHP_TIMEZONE_CODE } from 'global-constant'
import qs from 'qs'
import axios from 'axios'
import { MEMBERSHIP_TYPES } from 'global-constant'

function getStatusShowInSidebar(item) {
  const status = get(item, 'status')
  let result = false
  if (status === '1') {
    result = true
  }
  return result
}

function getEnabledShowSidebarOfSubCategories(subCategory) {
  const subcategories = get(subCategory, 'children', [])
  const enabledSubCategories = filter(subcategories, (item) =>
    getStatusShowInSidebar(item)
  )
  subCategory.children = enabledSubCategories
  return subCategory
}

export function getAllowShippingAttribute(address) {
  return get(address, 'extension_attributes.allow_shipping', true)
}

export function getNonServiceableArea(addresses) {
  if (!Array.isArray(addresses)) return []
  return addresses.filter(
    (address) => !get(address, 'extension_attributes.allow_shipping')
  )
}

export function formatListWithShippingAttribute(list) {
  const allowShippingItems = filter(list, (item) =>
    get(item, 'extension_attributes.allow_shipping')
  )
  const disallowShippingItems = filter(
    list,
    (item) => !get(item, 'extension_attributes.allow_shipping')
  )
  let formattedList = [...allowShippingItems]
  if (disallowShippingItems && disallowShippingItems.length) {
    formattedList = formattedList.concat([
      {
        id: 'divider',
        name: '----------------------------',
      },
      ...disallowShippingItems,
    ])
  }
  return formattedList
}

export function enabledCategoryInSidebar(categories) {
  const enabledShowInSidebarOfCategories = filter(categories, (item) =>
    getStatusShowInSidebar(item)
  )
  const enabledShowInSidebarOfSubCategories = enabledShowInSidebarOfCategories.map(
    (enabledCategory) => getEnabledShowSidebarOfSubCategories(enabledCategory)
  )
  return enabledShowInSidebarOfSubCategories
}

export const toJson = async (parseString) => {
  try {
    const jsonResult = await parseString.json()

    return jsonResult
  } catch (err) {
    return {}
  }
}

export const formatMoney = (amount) => {
  if (!amount) {
    // safe escape
    return 0
  }
  return amount.toLocaleString('en-PH', {
    style: 'currency',
    currency: 'PHP',
  })
}

export const safeJsonParse = (input = '', fallback) => {
  try {
    return JSON.parse(input)
  } catch (e) {
    return fallback
  }
}

export const sortWidgets = (data, isWebView) => {
  if (!Array.isArray(data)) {
    return []
  }
  const format = 'YYYY-MM-DD HH:mm:ss'
  const now = dayjs.tz()
  const afterCampaignWidgets = data.filter((item) => {
    return (
      item.start_time &&
      item.end_time &&
      now.isSameOrAfter(dayjs(formatDateToTimeZone(item.end_time, format)))
    )
  })
  const beforeCampaignWidgets = data.filter((item) => {
    return (
      item.start_time &&
      item.end_time &&
      now.isBefore(dayjs(formatDateToTimeZone(item.start_time, format)))
    )
  })
  const onCampaignWidgets = data.filter((item) => {
    return (
      item.start_time &&
      item.end_time &&
      now.isBetween(
        dayjs(formatDateToTimeZone(item.start_time, format)),
        dayjs(formatDateToTimeZone(item.end_time, format))
      )
    )
  })
  const noScheduledWidgets = data.filter((item) => !item.start_time)

  if (!isWebView) {
    return [
      ...sortCampaign(onCampaignWidgets, 'start_time'),
      ...sortCampaign(beforeCampaignWidgets, 'start_time'),
      ...noScheduledWidgets,
      ...sortCampaign(afterCampaignWidgets, 'end_time'),
    ]
  } else {
    return [
      ...noScheduledWidgets,
      ...sortCampaign(onCampaignWidgets, 'start_time'),
      ...sortCampaign(beforeCampaignWidgets, 'start_time'),
      ...sortCampaign(afterCampaignWidgets, 'end_time'),
    ]
  }
}

export const sortBanners = (data) => {
  if (!Array.isArray(data)) {
    return []
  }
  const format = 'YYYY-MM-DD HH:mm:ss'

  return data.filter((item) => {
    const now = dayjs.tz()
    if (!item.bnScheduleStart && !item.bnScheduleEnd) {
      return item
    }
    const startTime = get(item, 'bnScheduleStart')
      ? formatDateToTimeZone(item.bnScheduleStart, format)
      : null
    const endTime = get(item, 'bnScheduleEnd')
      ? formatDateToTimeZone(item.bnScheduleEnd, format)
      : null

    if (
      !endTime &&
      startTime &&
      startTime.isValid() &&
      now.isAfter(startTime)
    ) {
      return item
    }

    if (!startTime && endTime && endTime.isValid() && now.isBefore(endTime)) {
      return item
    }

    if (
      startTime &&
      endTime &&
      startTime.isValid() &&
      endTime.isValid() &&
      startTime.isBefore(endTime) &&
      now.isBetween(startTime, endTime)
    ) {
      return item
    }

    return false
  })
}

const isValidTimeRange = (item) => {
  if (!item) {
    return false
  }
  const now = dayjs.tz()
  const format = get(item, 'price') ? 'YYYY-MM-DD HH:mm:ss' : 'L LT'
  const startTimePH = formatDateToTimeZone(item.start_time, format)
  const endTimePH = formatDateToTimeZone(item.end_time, format)
  return (
    item.start_time &&
    item.end_time &&
    startTimePH.isValid() &&
    endTimePH.isValid() &&
    startTimePH.isBefore(endTimePH) &&
    now.isSameOrBefore(endTimePH)
  )
}

export const isValidSchedule = (scheduleStart, scheduleEnd) => {
  if (!scheduleStart || !scheduleEnd) {
    return false
  }
  const now = dayjs.tz()
  return (
    scheduleStart &&
    scheduleEnd &&
    scheduleStart.isValid() &&
    scheduleEnd.isValid() &&
    scheduleStart.isBefore(scheduleEnd) &&
    now.isBefore(scheduleStart)
  )
}

export const validCardItem = (item) => {
  const format = 'YYYY-MM-DD HH:mm:ss'
  const now = dayjs.tz()
  const scheduleStart = get(item, 'schedule_start')
    ? formatDateToTimeZone(item.schedule_start, format)
    : null
  const scheduleEnd = get(item, 'schedule_end')
    ? formatDateToTimeZone(item.schedule_end, format)
    : null
  if (!scheduleStart && !scheduleEnd) {
    return true
  }

  if (
    !scheduleEnd &&
    scheduleStart &&
    scheduleStart.isValid() &&
    now.isAfter(scheduleStart)
  ) {
    return true
  }

  if (
    !scheduleStart &&
    scheduleEnd &&
    scheduleEnd.isValid() &&
    now.isBefore(scheduleEnd)
  ) {
    return true
  }

  if (
    scheduleStart &&
    scheduleEnd &&
    scheduleStart.isValid() &&
    scheduleEnd.isValid() &&
    scheduleStart.isBefore(scheduleEnd) &&
    now.isBetween(scheduleStart, scheduleEnd)
  ) {
    return true
  }
  return false
}

export const getValidSchedules = (schedules) => {
  if (isEmpty(schedules)) {
    return []
  }
  return sortCampaign(schedules, 'start_time').filter((item) =>
    isValidTimeRange(item)
  )
}

export const sortCampaign = (schedules, prop = 'start_time') => {
  if (isEmpty(schedules)) {
    return []
  }
  return schedules.sort((a, b) => {
    return dayjs(a[prop]).diff(b[prop])
  })
}

export const isDuringCampaign = (schedule) => {
  if (isEmpty(schedule)) {
    return false
  }

  const now = dayjs.tz()
  return (
    schedule.start_time &&
    schedule.end_time &&
    dayjs(schedule.start_time).isValid() &&
    dayjs(schedule.end_time).isValid() &&
    now.isSameOrAfter(schedule.start_time) &&
    now.isBefore(schedule.end_time)
  )
}
export const getCampaignStock = (product, schedules) => {
  let stock = 0
  if (isDuringCampaign(schedules[0])) {
    stock = Number(get(schedules, `[0].stock`, 0))
  } else {
    stock = Number(get(product, 'extension_attributes.qty_all_store', 0))
  }
  return stock
}

export const getCampaignPrice = (product, schedules) => {
  let finalPrice = 0
  const currentPrice = get(product, 'extension_attributes.current_price', 0)
  const hasProductInCampaign = isDuringCampaign(schedules[0])

  if (!hasProductInCampaign && currentPrice) {
    finalPrice = Number(currentPrice)
  } else if (hasProductInCampaign) {
    finalPrice = Number(get(schedules, `[0].price`, 0))
  } else {
    finalPrice = Number(get(product, 'extension_attributes.final_price', 0))
  }
  return finalPrice
}

export const getAppLink = () => {
  if (isIOS) {
    return config.appLinkIOS
  }
  if (isAndroid) {
    return config.appLinkAndroid
  }
  return null
}

export const getCampaignDataByStoreCode = (campaignData) => {
  if (!Array.isArray(campaignData) || isEmpty(campaignData)) {
    return {}
  }
  const storeCode = getStoreCode() || 'default'
  const obj = campaignData.find(
    (campaign) => campaign.source_code === storeCode
  )

  return obj
    ? obj
    : campaignData.find((campaign) => campaign.source_code === 'default')
}

export const getBannerSlider = (data) => {
  if (!Array.isArray(data) || isEmpty(data)) {
    return []
  }
  return get(
    data.find((item) => item.type === 'bannerSlider'),
    'banner',
    []
  )
}

export const STORE_PICKUP_TIMESLOTS = ['09:00 - 14:00', '12:00 - 18:00']

export const formatDateToTimeZone = (date, format) => {
  return dayjs(date, format).tz(PHP_TIMEZONE_CODE)
}

export const isCampaignLandingPage = (schedules = [], pathname) => {
  return schedules.some(
    ({ campaign_url }) => pathname && campaign_url === pathname.substr(1)
  )
}

export const getSpecificSchedule = (schedules = [], pathname) => {
  return schedules.find(
    ({ campaign_url }) => pathname && campaign_url === pathname.substr(1)
  )
}

export const isNationwide = (address) => {
  return (
    get(address, 'extension_attributes.nationwide') || // user's address
    get(address, 'barangay.extension_attributes.nationwide') // guest's address
  )
}

export const getAppLinkRedirect = (pathname, search, productSKU) => {
  const { domain, apn, ibi, isi, img, title, desc } = config.appLink
  const origin = window.location.origin
  const url = origin + pathname
  const queryString = qs.stringify({
    apn,
    ibi,
    isi,
  })

  let linkRedirect = `${domain}/?link=${
    url + encodeURIComponent(search)
  }&${queryString}`

  if (productSKU) {
    linkRedirect = `${domain}/?link=${url}%23modal-pdp-${productSKU}&${queryString}`
  }

  return linkRedirect + `&si=${img}&st=${title}&sd=${desc}`
}

export const sortSideAds = (sideAdsBanner) => {
  const listSideAds = cloneDeep(sideAdsBanner)

  listSideAds.forEach((sideAd, i) => {
    const onlyStartTimeSideAds = sideAd.banner.filter((item) =>
      validScheduleSideAd(item)
    )

    const onlyEndTimeSideAds = sideAd.banner.filter((item) =>
      validScheduleSideAd(item, 'end')
    )

    const onCampaignSideAds = sideAd.banner.filter((item) =>
      validScheduleSideAd(item, 'on-going')
    )

    const defaultSideAd = sideAd.banner.filter(
      (item) =>
        item.bnStatus === '1' &&
        item.bnIsDefault === '1' &&
        !item.bnScheduleStart &&
        !item.bnScheduleEnd
    )

    const sideAds = sortPosition([
      ...onlyStartTimeSideAds,
      ...onlyEndTimeSideAds,
      ...onCampaignSideAds,
    ])

    if (isEmpty(sideAds) && defaultSideAd) {
      listSideAds[i].banner = defaultSideAd
    } else {
      listSideAds[i].banner = sideAds
    }
  })
  return listSideAds
}

const validScheduleSideAd = (
  schedule,
  mode = 'start',
  format = 'YYYY-MM-DD HH:mm:ss'
) => {
  const now = dayjs.tz()
  if (!schedule || schedule.bnStatus !== '1') {
    return null
  }

  const startTime = get(schedule, 'bnScheduleStart')
    ? formatDateToTimeZone(schedule.bnScheduleStart, format)
    : null

  const endTime = get(schedule, 'bnScheduleEnd')
    ? formatDateToTimeZone(schedule.bnScheduleEnd, format)
    : null

  if (mode === 'start') {
    return startTime && !endTime && now.isSameOrAfter(startTime)
  }

  if (mode === 'end') {
    return !startTime && endTime && now.isBefore(endTime)
  }

  if (mode === 'on-going') {
    return endTime && startTime && now.isBetween(startTime, endTime)
  }
}

export const sortPosition = (sideAds) => {
  if (isEmpty(sideAds)) {
    return []
  }

  return sideAds.sort((a, b) => {
    return parseInt(a.bnPosition) - parseInt(b.bnPosition)
  })
}

export const checkCartRule = (segments, code) => {
  if (!segments || !Array.isArray(segments)) {
    return null
  }

  return segments.find((item) => item.code === code && item.value > 0)
}

export const formatMembershipCode = (code) => {
  if (!code) {
    return ''
  } else if (String(code).length === 16) {
    return replace(code, /^(\d{4})(\d{4})(\d{4})(\d{4}).*/, '$1-$2-$3-$4')
  } else {
    return replace(code, /^(\d{4})(\d{8})(\d{2}).*/, '$1-$2-$3')
  }
}

export const isGrantedPermission = () => {
  return 'Notification' in window && Notification.permission === 'granted'
}

export const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then()
  } catch (e) {
    return false
  }
  return true
}

export const sortCondition = (data, prop = 'from') => {
  if (isEmpty(data)) {
    return []
  }
  return data.sort((a, b) => {
    return dayjs(a[prop]).diff(b[prop])
  })
}

export const sortProductsListData = (data) => {
  if (!Array.isArray(data)) {
    return []
  }
  const format = 'YYYY-MM-DD HH:mm:ss'
  const now = dayjs.tz()
  const afterCampaignWidgets = data.filter((item) => {
    return (
      item.from &&
      item.to &&
      now.isSameOrAfter(dayjs(formatDateToTimeZone(item.to, format)))
    )
  })
  const beforeCampaignWidgets = data.filter((item) => {
    return (
      item.from &&
      item.to &&
      now.isBefore(dayjs(formatDateToTimeZone(item.from, format)))
    )
  })
  const onCampaignWidgets = data.filter((item) => {
    return (
      item.from &&
      item.to &&
      now.isBetween(
        dayjs(formatDateToTimeZone(item.from, format)),
        dayjs(formatDateToTimeZone(item.to, format))
      )
    )
  })
  const noScheduledWidgets = data.filter((item) => !item.from)

  return [
    ...sortCondition(onCampaignWidgets, 'from'),
    ...sortCondition(beforeCampaignWidgets, 'from'),
    ...noScheduledWidgets,
    ...sortCondition(afterCampaignWidgets, 'to'),
  ]
}

export const getSubtotalWithDiscount = (totalSegments) => {
  if (!Array.isArray(totalSegments)) {
    return 0
  }
  const segment = totalSegments.find(
    (item) => item.code === 'subtotal_with_discount'
  )
  return segment ? segment.value : 0
}

export const calculateTotalCapitalCareProduct = (
  cartItems = [],
  cartTotalItems = []
) => {
  return cartItems.reduce((acc, cur) => {
    if (
      get(
        cur,
        'extension_attributes.product_data.extension_attributes.vendor'
      ) === CAPITAL_CARE_VENDOR
    ) {
      const currentItem = cartTotalItems.find(
        (item) => item.item_id === cur.item_id
      )

      return acc + get(currentItem, 'base_row_total', 0)
    } else {
      return acc
    }
  }, 0)
}

export const isBulkyItem = (item) =>
  get(item, 'extension_attributes.product_data.extension_attributes.is_bulky')

export const haveBulkyItemInCart = (cartItems) => {
  if (!Array.isArray(cartItems)) {
    return false
  }
  return cartItems.some((item) => isBulkyItem(item))
}

export const formatBirthday = (date) => {
  if (!date) {
    return ''
  }
  return dayjs(date).format('DD/MM/YYYY')
}

export const getTimeslotThreshold = (period) => {
  const initialCapacity = period.capacity
  let threshold = initialCapacity

  if (initialCapacity > 30) {
    threshold = 15
  } else if (initialCapacity > 20) {
    threshold = 10
  } else if (initialCapacity > 5) {
    threshold = 5
  }
  return threshold
}

export const getTimeslotStatus = (period) => {
  let status = ''
  const threshold = getTimeslotThreshold(period)
  const currentCapacity = period.currentCapacity

  if (period.isDisabled || currentCapacity == period.capacity) {
    status = 'FULL'
  } else if (currentCapacity > threshold) {
    status = 'NEARLY FULL'
  } else {
    status = 'AVAILABLE'
  }
  return status
}

export const mappingOptionsCascader = (options) => {
  if (!Array.isArray(options)) {
    return []
  }
  return options.map((option) => ({
    children: mappingOptionsCascader(option.subCategories),
    id: option.id,
    level: option.categoryLevel,
    name: option.categoryName,
    type_order: option.categoryOrder,
  }))
}

// Reorder Button Handle
export const validateOrderItems = async (orderID) => {
  try {
    const res = await axios.get(
      `${config.apiUrl}/order-detail/item-availability?order_id=${orderID}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getAccessToken()}`,
          sourcecode: getStoreCode() || '',
        },
      }
    )
    return res?.data
  } catch (error) {
    console.log('error', error)
  }
}

export const getProductFromOrder = async (items) => {
  try {
    const responses = await axios.all(
      items.map((item) => axios.get(`${config.apiUrl}/products/${item.sku}`))
    )
    return responses
      .map((res, idx) => {
        if (MEMBERSHIP_TYPES.includes(res?.data?.sku)) {
          return null
        }
        return {
          data: { ...res.data },
          qty: items[idx].qty_ordered,
        }
      })
      .filter((item) => !!item)
  } catch (error) {}
}

export const getValidSkuFrames = (frames) => {
  if (isEmpty(frames)) {
    return []
  }
  return sortFrames(frames, 'start_time').filter((item) =>
    isValidTimeRangeFrame(item)
  )
}

export const sortFrames = (frames, prop = 'start_time') => {
  if (isEmpty(frames)) {
    return []
  }
  return frames.sort((a, b) => {
    return dayjs(a[prop]).diff(b[prop])
  })
}

export const sortSchedules = (schedules, prop) => {
  if (isEmpty(schedules)) {
    return []
  }
  return schedules.sort((a, b) => {
    return dayjs(a[prop]).diff(b[prop])
  })
}

const isValidTimeRangeFrame = (item) => {
  if (!item) {
    return false
  }
  const now = dayjs.tz()
  const format = 'YYYY-MM-DD HH:mm:ss'
  const startTimePH = formatDateToTimeZone(item.start_time, format)
  const endTimePH = formatDateToTimeZone(item.end_time, format)
  return (
    item.start_time &&
    item.end_time &&
    startTimePH.isValid() &&
    endTimePH.isValid() &&
    startTimePH.isBefore(endTimePH) &&
    now.isSameOrBefore(endTimePH) &&
    now.isBetween(startTimePH, endTimePH)
  )
}

export const replaceStringByJSX = (str, find, replace) => {
  const parts = str.split(find)
  const result = []
  for (let i = 0; i < parts.length; i++) {
    result.push(parts[i])
    if (i < parts.length - 1) result.push(replace)
  }
  return result
}

// prevent vertical scrolling when swiping on a slider
let firstClientX, clientX

const preventTouch = (e) => {
  const minValue = 5 // threshold

  clientX = e.touches[0].clientX - firstClientX

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault()
    e.returnValue = false

    return false
  }
}

const touchStart = (e) => {
  firstClientX = e.touches[0].clientX
}

export const preventPassiveTouch = (containerRef) => {
  if (containerRef.current) {
    containerRef.current.addEventListener('touchstart', touchStart)
    containerRef.current.addEventListener('touchmove', preventTouch, {
      passive: false,
    })
  }
}

export const allowPassiveTouch = (containerRef) => {
  if (containerRef.current) {
    containerRef.current.removeEventListener('touchstart', touchStart)
    containerRef.current.removeEventListener('touchmove', preventTouch, {
      passive: false,
    })
  }
}
// End prevent vertical scrolling when swiping on a slider

export const getValidSneakPeak = (data, storeCode) => {
  const now = dayjs()
  const newData = data.filter(
    (x) =>
      x.status === '1' &&
      x.store === storeCode &&
      dayjs(x?.start_date).isValid() &&
      dayjs(x?.end_date).isValid() &&
      dayjs(x?.start_date).isBefore(x?.end_date) &&
      now.isBetween(x?.start_date, x.end_date)
  )

  if (isEmpty(newData)) {
    return null
  }

  return sortSchedules(newData, 'start_date')
}
export const mapDatastoreToAddress = (selectedStore, currentUser) => {
  return {
    country_id: 'PH',
    custom_attributes: [
      {
        attribute_code: 'location_label',
        value: selectedStore.datastore?.default_name,
      },
    ],
    customer_id: currentUser?.id,
    default_billing: false,
    default_shipping: false,
    firstname: currentUser?.firstname || 'First Name',
    lastname: currentUser?.lastname || 'Last Name',
    postcode: selectedStore.datastore?.postcode,
    region: {
      region_code: '',
      region: '',
      region_id: selectedStore.datastore?.region_id,
    },
    region_id: selectedStore.datastore?.region_id,
    city: selectedStore.datastore?.city_name,
    street: [selectedStore?.storeaddress],
    company: '',
    telephone: '00000000000',
    extension_attributes: {
      township: '',
      township_id: selectedStore.datastore?.township_id,
      city_id: selectedStore.datastore?.city_id,
    },
  }
}

import React from 'react'
import config from 'global-config'
import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import request, {
  getAccessToken,
  setAccessToken,
  setStoreCode,
} from 'utils/request'
import {
  setCurrentUser,
  checkUserIsMembership,
  checkMembershipIsExpired,
} from 'utils/validate'
import * as actions from './actions'
import * as constants from './constants'
import * as selectors from './selectors'
import * as checkoutActions from 'containers/Checkout/actions'
import * as homeActions from 'containers/Home/actions'
import * as landerActions from 'containers/Landers/actions'
import {
  showToastSuccessCustom,
  showUpdate,
  showError,
} from 'utils/notification'
import * as navigation from 'utils/navigation'
import { messages, SCREEN } from 'global-constant'
import { isMobile } from 'react-device-detect'

import { get, filter, find } from 'lodash'
import * as modalActions from '../Modals/actions'
import * as membershipActions from 'containers/Membership/actions'
import NotifySuccessNotification from 'component/product/NotifySuccessNotification'
import { directoryCountries } from 'constant/directoryCountries'
import { getCurrentUser } from 'utils/validate'
import { getDeviceId } from 'utils/localStorage'
import { getMessaging, deleteToken } from 'firebase/messaging'

// call request token api
function* onLoadSideBar(action) {
  const requestURL = `${config.apiUrl}/landers-megamenu-by-session/all`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadSideBarSuccess(res))
  } catch (err) {
    yield put(actions.loadSideBarFailed(err))
  }
}

function* onLoadSubMenuTop(action) {
  const requestURL = `${config.apiUrl}/landers-megamenu/menu-top`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadSubMenuTopSuccess(res))
  } catch (err) {
    yield put(actions.loadSubMenuTopFailed(err))
  }
}

// call request create cart guest
function* onCheckLocation(action) {
  const requestURL = `${config.apiUrl}/check-location`

  try {
    const cartId = yield call(request, requestURL, {
      method: 'POST',
    })

    yield put(actions.checkLocationSuccess(cartId))
  } catch (err) {
    yield put(actions.checkLocationFailed(err))
  }
}

function* onLoadGlobalConfig() {
  const requestURL = `${config.apiUrl}/landersrestapi/globalconfig`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadGlobalConfigSuccess(res))
  } catch (err) {
    yield put(actions.loadGlobalConfigFailed())
  }
}

// call request get user info
export function* onGetUserInfo(action) {
  const {
    needValidateMembership,
    needValidateDefaultShipping,
    membershipRequest,
  } = action
  let requestURL = `${config.apiUrl}/customers/me?needValidate=true`

  try {
    const userInfo = yield call(request, requestURL, {
      method: 'GET',
    })
    setCurrentUser(userInfo)
    yield put(actions.getUserInfoSuccess(userInfo))
    if (needValidateMembership) {
      const isMemberShip = checkUserIsMembership()
      const isMemberShipExpired = checkMembershipIsExpired()
      if (!isMemberShip) {
        yield put(modalActions.showRequestAddMembershipModal())
      } else if (isMemberShipExpired) {
        yield put(modalActions.showModalInformMembershipIsExpired())
      }
    }

    if (membershipRequest) {
      const membershipInfo = get(
        userInfo,
        'extension_attributes.membership_info',
        {}
      )
      yield put(
        membershipActions.renewMembership({
          renew_price: get(membershipInfo, 'renew_price'),
          code: get(membershipInfo, 'code'),
          name: get(membershipInfo, 'name'),
          package: get(membershipInfo, 'package'),
          is_expired_renew: true,
        })
      )
    }

    if (needValidateDefaultShipping) {
      const currentLocation = yield select((state) =>
        selectors.makeSelectCurrentLocation()(state)
      )
      const validAddresses = filter(get(userInfo, 'addresses', []), (address) =>
        get(address, 'extension_attributes.address_validation_data.source_code')
      )

      if (!currentLocation.barangay && !currentLocation.id) {
        const defaultAddress = find(
          validAddresses,
          (address) => address.default_shipping
        )

        if (defaultAddress) {
          yield put(actions.checkLocationSuccess(defaultAddress))
          yield put(homeActions.loadProducts())
        }
      }
    }
    return userInfo
  } catch (err) {
    yield put(actions.getUserInfoFailed(err))
    return null
  }
}

function* onLoadProvince(action) {
  const requestURL = `${config.apiUrl}/directory/countries/${action.payload.id}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadProvinceSuccess(get(res, 'available_regions')))
    yield put(actions.loadCountryNameSuccess(get(res, 'full_name_english', '')))
  } catch (err) {
    // ignore the CORS issue that occurs very often
    // by hardcoding with the list provice
    yield put(
      actions.loadProvinceSuccess(get(directoryCountries, 'available_regions'))
    )
    yield put(
      actions.loadCountryNameSuccess(
        get(directoryCountries, 'full_name_english', '')
      )
    )
  }
}

function* onLoadCity(action) {
  const requestURL = `${config.apiUrl}/directory/region/${action.payload.id}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadCitySuccess(get(res, 'available_cities')))
  } catch (err) {
    yield put(actions.loadCityFailed(err))
  }
}

function* onLoadBarangay(action) {
  const requestURL = `${config.apiUrl}/directory/city/${action.payload.id}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadBarangaySuccess(get(res, 'available_townships')))
  } catch (err) {
    yield put(actions.loadBarangayFailed(err))
  }
}

function* onLoadFooter(action) {
  const requestURL = `${config.apiUrl}/ldJsonCmsBlock/json-footer`
  try {
    yield put({ type: constants.LOAD_FOOTER_REQUEST })
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(actions.loadFooterSuccess(res))
  } catch (err) {
    yield put(actions.loadFooterFailed(err))
  }
}

function* onLoadAllCms(action) {
  const requestURL = `${config.apiUrl}/all-cms`
  try {
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(actions.loadAllCmsSuccess(res))
  } catch (err) {
    yield put(actions.loadAllCmsFailed(err))
  }
}

// call request create cart guest
function* onGetSubscribeProduct(action) {
  try {
    let requestURL = `${config.apiUrl}/ld-productnotifyme/notify`
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    })
    yield put(actions.getSubscribeProductSuccess(res))
    const user = yield select((state) =>
      selectors.makeSelectCurrentUser()(state)
    )
    if (!res.error) {
      if (res.message === 'You are already subscribed to this product.') {
        showUpdate(messages.alreadySubscribed)
      } else {
        showToastSuccessCustom(<NotifySuccessNotification email={user.email} />)
      }
    } else {
      showError('Subscribe product failed, try again or contact support')
    }
  } catch (err) {
    console.log('err:', err)
    showError('Subscribe product failed, try again or contact support')
    yield put(actions.getSubscribeProductFailed(err))
  }
}

function* onUpdateShippingAddress() {
  const userInfo = yield select((state) =>
    selectors.makeSelectCurrentUser()(state)
  )
  const shippingAddress = find(get(userInfo, 'addresses', []), {
    default_shipping: true,
  })
  yield put(checkoutActions.setShippingAddress(shippingAddress))
  yield put(actions.checkLocationSuccess(shippingAddress))
}

function* onLoadCategoryMenu() {
  try {
    const url = `${config.apiUrl}/landers-megamenu/vertical-menu-left`
    const res = yield call(request, url)
    yield put({
      type: constants.LOAD_CATEGORY_MENU_LEFT_SUCCESS,
      payload: res,
    })
  } catch (error) {
    yield put({
      type: constants.LOAD_CATEGORY_MENU_LEFT_FAILURE,
    })
    showError(error.message || 'Failed to get category menu!')
  }
}

function* onLoadSideAds() {
  try {
    const url = `${config.apiUrl}/landers-bannerslider/right1`
    const res = yield call(request, url)
    yield put({
      type: constants.LOAD_SIDEADS_SUCCESS,
      payload: res,
    })
  } catch (error) {
    yield put({
      type: constants.LOAD_SIDEADS_FAILURE,
    })
    showError(error.message || 'Failed to get banner slider!')
  }
}

async function deleteFCMToken() {
  try {
    const dispatch = navigation.getDispatch()
    const userId = getCurrentUser()?.id
    const accessToken = getAccessToken()
    const messaging = getMessaging()

    if (!!userId && !!accessToken) {
      dispatch(landerActions.deleteFCMToken({ userId, accessToken }))
      await deleteToken(messaging)
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onLogout(action) {
  deleteFCMToken()
  setAccessToken('')
  setStoreCode('')
  setCurrentUser({})
  yield all([
    put(actions.clearCurrentLocation()),
    put(checkoutActions.setShippingAddress({})),
    put(checkoutActions.getLastOrderOOSSuccess('')),
    put(checkoutActions.setCancellationOption('')),
    put(checkoutActions.setDefaultCancellationOption('')),
    put(checkoutActions.createCart()),
    put(checkoutActions.applyCouponSuccess('')),
    put(homeActions.loadProducts()),
    put(actions.showSideBar(false)),
  ])

  navigation.navigate(SCREEN.HOME)
}

function* onLoadStoreAddresses(action) {
  const requestURL = `${config.apiUrl}/baseapi/globalconfig`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(
      actions.loadStoreAddressesSuccess(get(res, 'store_pickup_addresses', []))
    )

    yield put(
      actions.loadSpecialCategoryIDSuccess(
        get(res, 'special_product_category', 0)
      )
    )

    yield put(actions.loadPreferredBranches(get(res, 'preferred_branches', [])))

    const configOOS = {
      oos_cancellation: get(res, 'oos_cancellation', []),
      oos_note: get(res, 'oos_footer', ''),
      oos_description: get(res, 'oos_header', ''),
      oos_default: get(res, 'oos_default'),
    }

    yield put(actions.loadOutOfStockConfig(configOOS))
    const footerConfig = get(res, 'footer_config', [])
    yield put(actions.loadFooterConfig(footerConfig))
  } catch (err) {
    yield put(actions.loadStoreAddressesFailed(err))
  }
}

function* onUpdateFCMToken(action) {
  const requestURL = `${config.nmsUrl}/nms/tokens`
  const bodyData = {
    deviceID: getDeviceId(),
    platform: isMobile ? 'msite' : 'web',
    token: action.payload.fcmToken,
    userID: `${getCurrentUser().id}`,
  }
  try {
    yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(bodyData),
    })
  } catch (err) {
    console.log('err', err)
  }
}
function* onDeleteFCMToken(action) {
  const requestURL = `${config.nmsUrl}/nms/tokens`

  const bodyData = {
    deviceID: getDeviceId(),
    platform: isMobile ? 'msite' : 'web',
    userID: `${action.payload.userId}`,
  }

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        PlatformName: 'LANDERS',
        Authorization: `Bearer ${action.payload.accessToken}`,
      },
      body: JSON.stringify(bodyData),
    })
  } catch (err) {
    console.log('err', err)
  }
}

// Individual exports for testing
export default function* globalSaga() {
  yield takeLatest(constants.LOAD_CATEGORY_MENU_LEFT, onLoadCategoryMenu)
  yield takeLatest(constants.LOAD_SIDEADS, onLoadSideAds)
  yield takeLatest(constants.CHECK_LOCATION, onCheckLocation)
  yield takeLatest(constants.LOAD_SIDEBAR, onLoadSideBar)
  yield takeLatest(constants.LOAD_SUBMENUTOP, onLoadSubMenuTop)
  yield takeLatest(constants.GET_USER_INFO, onGetUserInfo)
  yield takeLatest(constants.UPDATE_SHIPPING_ADDRESS, onUpdateShippingAddress)
  yield takeLatest(constants.LOAD_PROVINCE, onLoadProvince)
  yield takeLatest(constants.LOAD_CITY, onLoadCity)
  yield takeLatest(constants.LOAD_BARANGAY, onLoadBarangay)
  yield takeLatest(constants.LOAD_FOOTER, onLoadFooter)
  yield takeLatest(constants.SUBSCRIBE_PRODUCT, onGetSubscribeProduct)
  yield takeLatest(constants.LOGOUT, onLogout)
  yield takeLatest(constants.LOAD_ALL_CMS, onLoadAllCms)
  yield takeLatest(constants.LOAD_GLOBAL_CONFIG, onLoadGlobalConfig)
  yield takeLatest(constants.LOAD_STORE_ADDRESSES, onLoadStoreAddresses)
  yield takeLatest(constants.UPDATE_FCM_TOKEN, onUpdateFCMToken)
  yield takeLatest(constants.DELETE_FCM_TOKEN, onDeleteFCMToken)
}

import { all } from 'redux-saga/effects'
import globalSaga from 'containers/Landers/saga'
import checkoutSaga from 'containers/Checkout/saga'
import authenSaga from 'containers/Authentication/saga'
import modalSaga from 'containers/Modals/saga'
import searchSaga from 'containers/Search/saga'
import accountSaga from 'containers/Account/saga'
import expSaga from 'containers/TheLandersExperience/saga'
import shoppingListSaga from 'containers/ShoppingList/saga'
import membershipSaga from 'containers/Membership/saga'
import homeSaga from 'containers/Home/saga'
import productDetailSaga from 'containers/ProductDetail/saga'
import CategorySage from 'containers/Category/saga'
import BreadCrumbSage from 'component/BreadCrumb/saga'
import TrackAndTraceSaga from 'containers/TrackAndTrace/saga'
import DeliveryAreasSaga from 'containers/DeliveryAreas/saga'
import HelpSaga from 'containers/Help/saga'
import VoucherSaga from 'containers/Vouchers/saga'

export default function* rootSaga() {
  yield all([
    globalSaga(),
    checkoutSaga(),
    authenSaga(),
    modalSaga(),
    searchSaga(),
    accountSaga(),
    expSaga(),
    shoppingListSaga(),
    membershipSaga(),
    homeSaga(),
    productDetailSaga(),
    CategorySage(),
    BreadCrumbSage(),
    TrackAndTraceSaga(),
    DeliveryAreasSaga(),
    HelpSaga(),
    VoucherSaga(),
  ])
}

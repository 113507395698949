import * as constant from './constants'

export const initialState = {
  subCategoryLoading: false,
  categoryBlockLoading: false,
  arrange: 0,
  filter: {},
  secondaryFilter: {
    ld_display: null,
    product_list_dir: 'ASC',
    product_list_order: null,
  },
  priceFilter: {},
  brandFilter: {},
  lifeStyleFilter: {},
  subCategory: {},
  currentPage: 1,
  productCategory: [],
  productList: null,
  slugData: {},
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.LOAD_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: action.payload,
        subCategoryLoading: false,
        productCategory: [],
      }
    case constant.LOAD_SUB_CATEGORY_FAIL:
      return {
        ...state,
        subCategory: {},
        subCategoryLoading: false,
      }
    case constant.SAVE_SLUG_DATA:
      return {
        ...state,
        slugData: action.payload,
      }
    case constant.CLEAR_SLUG_DATA:
      return {
        ...state,
        slugData: {},
      }
    case constant.SAVE_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategory: [...state.productCategory, action.payload],
        productCategoryLoading: false,
      }
    case constant.SAVE_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload,
        currentPage: action.currentPage,
      }
    case constant.CLEAR_PRODUCT_LIST:
      return {
        ...state,
        productList: null,
        currentPage: 1,
      }
    case constant.LOAD_SUB_CATEGORY:
      return {
        ...state,
        subCategoryLoading: true,
      }
    case constant.LOAD_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategoryLoading: true,
      }
    case constant.LOAD_PRODUCT_LIST:
      return {
        ...state,
      }
    case constant.SAVE_SECONDARY_FILTER:
      return {
        ...state,
        secondaryFilter: action.payload.isReset
          ? {}
          : { ...state.secondaryFilter, ...action.payload },
      }
    case constant.SAVE_ARRANGE:
      return {
        ...state,
        arrange: action.payload,
      }
    case constant.SAVE_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    case constant.SAVE_BRAND_FILTER:
      return {
        ...state,
        brandFilter: action.payload,
      }
    case constant.SAVE_PRICE_FILTER:
      return {
        ...state,
        priceFilter: action.payload,
      }
    case constant.SAVE_LIFE_STYLE_FILTER:
      return {
        ...state,
        lifeStyleFilter: action.payload,
      }
    default:
      return state
  }
}
export default reducer

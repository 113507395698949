import { call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import {
  LOAD_RELATED_PRODUCT,
  LOAD_SHOPPING_LIST_ADDED,
  ADD_PRODUCT_REVIEW,
} from './constants'
import productDetailServices from 'services/productDetail'
import { get } from 'lodash'

function* onLoadRelatedProduct(action) {
  try {
    const data = yield call(
      [productDetailServices, 'getProductRelated'],
      action.payload
    )
    if (Array.isArray(data)) {
      yield put(actions.saveRelatedProduct(data))
    } else {
      console.error('get relatred product error')
    }
  } catch (err) {}
}

function* onLoadShoppingListByProductId(action) {
  try {
    const data = yield call(
      [productDetailServices, 'getShoppingListById'],
      action.payload
    )
    yield put(actions.saveShoppingListAdded(data))
  } catch (err) {
    console.error('add review error')
  }
}

function* onAddProductReview(action) {
  const resetForm = action.resetForm
  try {
    const data = yield call(
      [productDetailServices, 'addProductReview'],
      action.payload
    )
    const isSuccess = get(data, '[0].status')
    if (isSuccess) {
      yield put(actions.addProductReviewSuccess('success'))
      resetForm()
    }
  } catch (err) {
    yield put(actions.addProductReviewFailed('failed'))
  }
}

export default function* expSaga() {
  yield takeLatest(LOAD_RELATED_PRODUCT, onLoadRelatedProduct)
  yield takeLatest(LOAD_SHOPPING_LIST_ADDED, onLoadShoppingListByProductId)
  yield takeLatest(ADD_PRODUCT_REVIEW, onAddProductReview)
}

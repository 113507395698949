import slugify from '@sindresorhus/slugify'
import SITEURL from 'constant/urls'
import { SCREEN } from 'global-constant'
import { get, startsWith, isEmpty } from 'lodash'
import queryString from 'qs'
import config from 'global-config'

export const getSubmenuLinkName = (path1, path2) => {
  // let arr = path.split('/')
  let sideMenu = this.props.sideMenu
  let name = ''
  sideMenu.forEach((item, index) => {
    if (item.url.indexOf(path1) !== -1) {
      item.children.forEach((i) => {
        if (i.url.indexOf(path2) !== -1) {
          name = i.name
        }
      })
    }
  })
  return name
}
export const getUrlFromId = (id, sideMenu) => {
  let url = ''
  sideMenu.forEach((item, index) => {
    item.children.forEach((i) => {
      if (i.categoryId.indexOf(id) !== -1) {
        url = i.url
      }
    })
  })
  return url
}
export const isProductLink = ({ search }) => {
  const sku = queryString.parse(search, { ignoreQueryPrefix: true }).sku
  return sku
}
export const isPaging = () => {
  const url = window.location.search
  const p = queryString.parse(url, { ignoreQueryPrefix: true }).p
  return p
}
export const getSKU = () => {
  const url = window.location.search
  const sku = queryString.parse(url, { ignoreQueryPrefix: true }).sku
  return sku
}
export const isJustCloseProductModal = () => {
  const url = window.location.search
  const v = queryString.parse(url, { ignoreQueryPrefix: true }).v
  return v
}

export const getPathFromUrl = (url) => {
  if (url) {
    url = url.replace('.html', '')
    return ('/' + url).replace('//', '/')
  } else {
    return ''
  }
}
export const getPathFromFullUrl = (url) => {
  if (url) {
    let newUrl = url
    // case 1
    if (newUrl.includes('http://') || newUrl.includes('https://')) {
      newUrl = newUrl.replace('://', '')
      // case 3:
    } else if (newUrl.indexOf('/') !== 0) {
      newUrl = '/' + newUrl
    }
    let indexStartPathName = newUrl.indexOf('/')
    let indexStartQuery = newUrl.indexOf('?')
    // get path name same case 2
    if (indexStartQuery > 0) {
      return newUrl.substring(indexStartPathName, indexStartQuery)
    }
    return newUrl.substring(indexStartPathName, newUrl.length)
  } else {
    return ''
  }
}

export const getUrlByCurrentServer = (url) => {
  // TODO: just get pathname from url if this url is lander url
  if (url.includes(config.baseUrl)) {
    return getPathFromFullUrl(url)
  } else {
    return url
  }
}
export const isCategoryPage = () => {
  let detect = true
  let path = window.location.pathname
  // TODO: need create a store (all Site)
  const listSite = {
    ...SITEURL,
    ...SCREEN,
  }
  for (let key in listSite) {
    if (
      listSite[key] &&
      listSite[key] !== '/' &&
      startsWith(path, listSite[key])
    ) {
      detect = false
    }
  }
  return detect
}
export const isSearchResultPage = () => {
  let detect = false
  let path = window.location.pathname

  if (SITEURL.searchResult === path) {
    console.log('yes')
    detect = true
  }

  return detect
}
export const countSegment = () => {
  let u = window.location.pathname
  let arr = u.split('/')
  let object = {}
  arr.shift()
  object.url = ''
  object.count = arr.length
  arr.forEach((item, index) => {
    if (index === 0) {
      object.url = item
    } else {
      object.url = object.url + '/' + item
    }
  })
  return object
}
export const getProgressiveSegment = () => {
  let u = window.location.pathname
  let arr = u.split('/')
  arr.shift()
  let progressiveSegment = []
  arr.forEach((item, index) => {
    if (index === 0) {
      progressiveSegment.push(item)
    } else {
      let next = progressiveSegment[index - 1] + '/' + item
      progressiveSegment.push(next)
    }
  })
  return progressiveSegment
}
export const getNextPage = () => {
  const string = window.location.search
  const parsed = queryString.parse(string, { ignoreQueryPrefix: true })

  let page = parsed.p

  if (page === undefined) {
    page = 1
  }
  return parseInt(page)
}

export const createProductLink = (item) => {
  if (isEmpty(item)) return ''
  return `${window.location.origin}/?${queryString.stringify({
    sku: item.sku,
    name: slugify(item.name),
  })}`
}

export const isCheckoutCartPage = (currentPathName) => {
  return startsWith(currentPathName, '/checkout/cart')
}

export const isCheckoutPaymentPage = (currentPathName) => {
  return currentPathName.includes('/checkout/onepage')
}

export const isOtherCheckoutPages = (router) => {
  const currentPathName = get(router, 'location.pathname', '')

  if (isCheckoutCartPage(currentPathName)) {
    return false
  }

  if (isCheckoutPaymentPage(currentPathName)) {
    return false
  }

  return startsWith(currentPathName, '/checkout')
}

export const isHomePage = (router) => {
  const currentPathName = get(router, 'location.pathname', '')
  return currentPathName === SCREEN.HOME
}

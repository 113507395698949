/*
 *
 * Membership actions
 *
 */

import * as constant from './constants'

// GET MEMBERSHIP PRODUCTS
export function loadMembershipProducts() {
  return {
    type: constant.MEMBERSHIP_PRODUCTS,
  }
}

export function loadMembershipProductsSuccess(payload) {
  return {
    type: constant.MEMBERSHIP_PRODUCTS_SUCCESS,
    payload,
  }
}

export function loadMembershipProductsFailed(error) {
  return {
    type: constant.MEMBERSHIP_PRODUCTS_FAILED,
    error,
  }
}

// GET MEMBERSHIP BANNER
export function loadMembershipBanner() {
  return {
    type: constant.LOAD_MEMBERSHIP_BANNER,
  }
}

export function loadMembershipBannerSuccess(payload) {
  return {
    type: constant.LOAD_MEMBERSHIP_BANNER_SUCCESS,
    payload,
  }
}

export function loadMembershipBannerFailed(error) {
  return {
    type: constant.LOAD_MEMBERSHIP_BANNER_FAILED,
    error,
  }
}

export function applyMembership(requestData) {
  return {
    type: constant.APPLY_MEMBERSHIP,
    requestData,
  }
}

export function applyMembershipSuccess(payload) {
  return {
    type: constant.APPLY_MEMBERSHIP_SUCCESS,
    payload,
  }
}

export function applyMembershipFailed(error) {
  return {
    type: constant.APPLY_MEMBERSHIP_FAILED,
    error,
  }
}

export function verifyMembership(requestData) {
  return {
    type: constant.VERIFY_MEMBERSHIP,
    requestData,
  }
}

export function verifyMembershipSuccess(payload) {
  return {
    type: constant.VERIFY_MEMBERSHIP_SUCCESS,
    payload,
  }
}

export function verifyMembershipFailed(error) {
  return {
    type: constant.VERIFY_MEMBERSHIP_FAILED,
    error,
  }
}

export function renewMembership(requestData) {
  return {
    type: constant.RENEW_MEMBERSHIP,
    requestData,
  }
}

export function renewMembershipSuccess(payload) {
  return {
    type: constant.RENEW_MEMBERSHIP_SUCCESS,
    payload,
  }
}

export function renewMembershipFailed(error) {
  return {
    type: constant.RENEW_MEMBERSHIP_FAILED,
    error,
  }
}

export function linkMembership(requestData) {
  return {
    type: constant.LINK_MEMBERSHIP,
    requestData,
  }
}

export function linkMembershipSuccess(payload) {
  return {
    type: constant.LINK_MEMBERSHIP_SUCCESS,
    payload,
  }
}

export function linkMembershipFailed(error) {
  return {
    type: constant.LINK_MEMBERSHIP_FAILED,
    error,
  }
}

export function resetLinkMembershipForm() {
  return {
    type: constant.RESET_LINK_MEMBERSHIP_FORM,
  }
}

export function verifyPrimaryMembership(payload) {
  return {
    type: constant.VERIFY_PRIMARY_MEMBERSHIP,
    payload,
  }
}

export function verifyPrimaryMembershipSuccess(payload) {
  return {
    type: constant.VERIFY_PRIMARY_MEMBERSHIP_SUCCESS,
    payload,
  }
}

export function verifyPrimaryMembershipFailed(error) {
  return {
    type: constant.VERIFY_PRIMARY_MEMBERSHIP_FAILED,
    error,
  }
}

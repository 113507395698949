export const ACTION_TYPE = {
  APPLY: 'apply',
  CLAIM: 'claim',
  USE_NOW: 'useNow',
}

export const VOUCHER_TYPE = {
  CLAIMED: 'claimed',
  COLLECTIBLE: 'collectible',
  STACKABLE: 'stackable',
}

export const VOUCHER_CATEGORY = {
  GENERIC: 'generic',
  SPECIFIC: 'specific',
}

export const VOUCHER_LABELS_DEFAULT = {
  category: {
    [VOUCHER_CATEGORY.GENERIC]: {
      menu_label: 'generic',
    },
    [VOUCHER_CATEGORY.SPECIFIC]: {
      menu_label: 'other',
    },
  },
  type: {
    [VOUCHER_TYPE.CLAIMED]: {
      voucher_menu_prefix: 'My',
    },
    [VOUCHER_TYPE.COLLECTIBLE]: {
      voucher_menu_prefix: 'Claim',
    },
  },
}

// COLLECTIBLE VOUCHERS
export const GET_VOUCHERS_COLLECTIBLE =
  'landers/Vouchers/GET_VOUCHERS_COLLECTIBLE'
export const GET_VOUCHERS_COLLECTIBLE_SUCCESS =
  'landers/Vouchers/GET_VOUCHERS_COLLECTIBLE_SUCCESS'
export const GET_VOUCHERS_COLLECTIBLE_FAILED =
  'landers/Vouchers/GET_VOUCHERS_COLLECTIBLE_FAILED'

// CLAIMED VOUCHERS
export const GET_VOUCHERS_CLAIMED = 'landers/Vouchers/GET_VOUCHERS_CLAIMED'
export const GET_VOUCHERS_CLAIMED_SUCCESS =
  'landers/Vouchers/GET_VOUCHERS_CLAIMED_SUCCESS'
export const GET_VOUCHERS_CLAIMED_FAILED =
  'landers/Vouchers/GET_VOUCHERS_CLAIMED_FAILED'

// SAVE CLAIMED VOUCHERS
export const SAVE_CLAIM_VOUCHER = 'landers/Vouchers/SAVE_CLAIM_VOUCHER'
export const SAVE_CLAIM_VOUCHER_SUCCESS =
  'landers/Vouchers/SAVE_CLAIM_VOUCHER_SUCCESS'
export const SAVE_CLAIM_VOUCHER_FAILED =
  'landers/Vouchers/SAVE_CLAIM_VOUCHER_FAILED'

export const SET_VOUCHER_IS_PROCESSING =
  'landers/Vouchers/SET_VOUCHER_IS_PROCESSING'

import config from 'global-config'
import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import * as categorySelectors from 'containers/Category/selectors'
import { isEmpty, get } from 'lodash'
import request from 'utils/request'
import { PAGE_CONFIG } from 'global-constant'

import * as actions from './actions'
import * as constant from './constants'
import * as globalActions from 'containers/Landers/actions'

function* onLoadSubCategories(action) {
  const {
    id,
    pageSize = PAGE_CONFIG.pageSize,
    isLoadMore,
    isIgnoreProducts,
    isSecondaryFilter,
  } = action.payload
  let currentPage = yield select((state) =>
    categorySelectors.makeSelectCurrentPage()(state)
  )
  if (isLoadMore) {
    currentPage++
  } else {
    currentPage = 1
  }
  let requestURL = `${config.apiUrl}/landers-subcategory-filter?cat=${id}&page_size=${pageSize}&current_page=${currentPage}`
  const filter = yield select((state) =>
    categorySelectors.makeSelectFilter()(state)
  )
  const secondaryFilter = yield select((state) =>
    categorySelectors.makeSelectSecondaryFilter()(state)
  )
  const productsCategoryCurrent = yield select((state) =>
    categorySelectors.makeSelectProductList()(state)
  )
  if (isLoadMore) {
    requestURL += '&ignore_filter=1'
  }
  if (filter.brand) {
    requestURL += `&brand=${filter.brand.value}`
  }
  if (filter.lifestyleBenefits) {
    requestURL += `&lifestyle_benefits=${filter.lifestyleBenefits.value}`
  }
  if (filter.price) {
    requestURL += `&price=${filter.price.value}`
  }
  if (secondaryFilter.product_list_order === 'price_desc') {
    requestURL += '&product_list_order=price'
  } else {
    requestURL += `&product_list_order=${
      secondaryFilter.product_list_order || null
    }`
  }
  requestURL += `&product_list_dir=${secondaryFilter.product_list_dir}&ld_display=${secondaryFilter.ld_display}`
  if (isIgnoreProducts) {
    requestURL = `${config.apiUrl}/landers-subcategory-filter?cat=${id}&ignore_products=${isIgnoreProducts}`
  }
  try {
    // support show placeholder / loading
    if (currentPage === 1) {
      yield put(
        globalActions.showLoader({
          isLoadingCategory: true,
          isSecondaryFilter,
        })
      )
    } else {
      yield put(
        globalActions.showLoader({
          isLoadMoreCategory: true,
        })
      )
    }
    yield put(actions.loadProductCategory())
    let res = yield call(request, requestURL, {
      method: 'GET',
    })
    const productsData = get(res, 'products_data', [])
    res.isEndOfCategoryProduct = isEmpty(productsData)
    if (currentPage !== 1) {
      // update new list product when load more
      res.products_data = [...productsCategoryCurrent, ...productsData]
    }
    yield put(actions.saveProductList(res.products_data, currentPage))
    if (!isLoadMore) {
      yield put(actions.loadSubCategoriesSuccess(res, currentPage))
    }
  } catch (error) {
    yield put(actions.loadSubCategoriesFailed(error))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* onLoadProductCategory(action) {
  if (!action.payload) {
    return
  }
  const requestURL = `${config.apiUrl}/landers-subcategory-product?cat=${action.payload}`
  try {
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(
      actions.saveProductCategory({ id: action.payload, products_data: res })
    )
  } catch (err) {
  } finally {
  }
}

export default function* homeSaga() {
  yield takeLatest(constant.LOAD_SUB_CATEGORY, onLoadSubCategories)
  yield takeEvery(constant.LOAD_PRODUCT_CATEGORY, onLoadProductCategory)
}

import * as constant from './constants'

// COLLECTIBLE VOUCHERS
export function getCollectibleVouchers(params) {
  return {
    type: constant.GET_VOUCHERS_COLLECTIBLE,
    params,
  }
}

export function getCollectibleVouchersSuccess(payload) {
  return {
    type: constant.GET_VOUCHERS_COLLECTIBLE_SUCCESS,
    payload,
  }
}

export function getCollectibleVouchersFailed(payload) {
  return {
    type: constant.GET_VOUCHERS_COLLECTIBLE_FAILED,
    payload,
  }
}

// CLAIM VOUCHER
export function getClaimedVouchers(payload) {
  return {
    type: constant.GET_VOUCHERS_CLAIMED,
    payload,
  }
}

export function getClaimedVouchersSuccess(payload) {
  return {
    type: constant.GET_VOUCHERS_CLAIMED_SUCCESS,
    payload,
  }
}

export function getClaimedVouchersFailed(payload) {
  return {
    type: constant.GET_VOUCHERS_CLAIMED_FAILED,
    payload,
  }
}

export function saveClaimedVoucher(payload) {
  return {
    type: constant.SAVE_CLAIM_VOUCHER,
    payload,
  }
}

export function saveClaimedVoucherSuccess(payload) {
  return {
    type: constant.SAVE_CLAIM_VOUCHER_SUCCESS,
    payload,
  }
}

export function setVoucherIsProcessing(payload) {
  return {
    type: constant.SET_VOUCHER_IS_PROCESSING,
    payload,
  }
}

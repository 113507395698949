
// Get shopping now list
export const GET_SHOPPING_NOW = 'landers/CheckoutCart/GET_SHOPPING_NOW';
export const GET_SHOPPING_NOW_SUCCESS = 'landers/CheckoutCart/GET_SHOPPING_NOW_SUCCESS';
export const GET_SHOPPING_NOW_FAILED = 'landers/CheckoutCart/GET_SHOPPING_NOW_FAILED';

// Get progressbar info
export const GET_PROGRESSBAR = 'landers/CheckoutCart/GET_PROGRESSBAR';
export const GET_PROGRESSBAR_SUCCESS = 'landers/CheckoutCart/GET_PROGRESSBAR_SUCCESS';
export const GET_PROGRESSBAR_FAILED = 'landers/CheckoutCart/GET_PROGRESSBAR_FAILED';


// Get recomment product
export const GET_RECOMMEND_PRODUCT = 'recomment products';
export const GET_RECOMMEND_PRODUCT_SUCCESS = 'recomment products success';
export const GET_RECOMMEND_PRODUCT_FAILED = 'recomment products failed';

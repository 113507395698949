import * as constant from './constants';

export function searchHeader(keyword, categoryId) {
  return {
    type: constant.SEARCH_HEADER,
    keyword,
    categoryId,
  };
}

export function searchHeaderSuccess(payload) {
  return {
    type: constant.SEARCH_HEADER_SUCCESS,
    payload,
  };
}

export function searchHeaderFailed(error) {
  return {
    type: constant.SEARCH_HEADER_FAILED,
    error,
  };
}

export function resetSearchResult() {
  return {
    type: constant.RESET_SEARCH,
  };
}

// SEARCH RESULTS
export function loadSearchResultsAction(payload) {
  return {
    type: constant.LOAD_SEARCH_RESULTS,
    payload
  };
}

export function loadSearchResultsActionSuccess(payload, currentPage, isLoadMore) {
  return {
    type: constant.LOAD_SEARCH_RESULTS_SUCCESS,
    payload,
    currentPage,
    isLoadMore,
  };
}

export function loadSearchResultsActionFail(error) {
  return {
    type: constant.LOAD_SEARCH_RESULTS_FAILED,
    error,
  };
}

export function loadListCategorySearch() {
  return {
    type: constant.LOAD_LIST_CATEGORY_SEARCH,
  };
}

export function saveListCategorySearch(payload) {
  return {
    type: constant.SAVE_LIST_CATEGORY_SEARCH,
    payload,
  };
}

export const LOAD_TRACK_AND_TRACE =
  'landers/track-and-trace/LOAD_TRACK_AND_TRACE'
export const SAVE_TRACK_AND_TRACE =
  'landers/track-and-trace/SAVE_TRACK_AND_TRACE'

export const LOAD_ORDER_TRACER = 'landers/track-and-trace/LOAD_ORDER_TRACER'
export const SAVE_ORDER_TRACER = 'landers/track-and-trace/SAVE_ORDER_TRACER'

export const historyDetail = [
  {
    status: 'Accepted',
    className: 'image-order-status-new',
  },
  {
    status: 'Being Fulfilled',
    className: 'image-order-status-active',
  },
  {
    status: 'Out for Delivery',
    className: 'image-order-status-intransit',
  },
  {
    status: 'Delivered',
    className: 'image-order-status-delivered',
  },
  {
    status: 'Completed',
    className: 'image-order-status-completed',
  },
]

export const TRACKING_HISTORY = {
  DELIVERED: 'Delivered',
  OUT_FOR_DELIVERY: 'Out for Delivery',
  ACCEPTED: 'Accepted by our Delivery Partner',
  READY_FOR_DELIVERY: 'Ready for delivery', // missing remark
  BEING_FULLFILLED: 'Being Fulfilled',
  COMPLETED: 'Order Accepted',
}

export const TRACKING_STATUS_COMPLETION = {
  0: 0,
  1: 25,
  2: 50,
  3: 80,
  4: 100,
}

export const TRACKING_STATUS_COMPLETION_MOBILE = {
  0: 0,
  1: 30,
  2: 70,
  3: 100,
  4: 100,
}

/*
 *
 * Authorize actions
 *
 */
import * as constant from './constants'

// LOGIN ACTION
export function login(username, password, params = {}, callback) {
  return {
    type: constant.LOGIN,
    username,
    password,
    params,
    callback,
  }
}

export function loginSuccess(payload) {
  //will hide
  return {
    type: constant.LOGIN_SUCCESS,
    payload,
  }
}

export function loginFailed(error) {
  return {
    type: constant.LOGIN_FAILED,
    error,
  }
}

//SIGN UP
export function signUpAction(signUpRequest, params = {}) {
  return {
    type: constant.SIGN_UP,
    signUpRequest,
    params,
  }
}

export function signUpSuccessAction(payload) {
  return {
    type: constant.SIGN_UP_SUCCESS,
    payload,
  }
}

export function signUpFailedAction(error) {
  return {
    type: constant.SIGN_UP_FAILED,
    error,
  }
}

export function checkMemberShipAction(signUpRequest) {
  return {
    type: constant.CHECK_MEMBERSHIP,
    signUpRequest,
  }
}

export function checkMemberShipActionSuccess(payload) {
  return {
    type: constant.CHECK_MEMBERSHIP_SUCCESS,
    payload,
  }
}

export function checkMemberShipActionFailed(error) {
  return {
    type: constant.CHECK_MEMBERSHIP_FAILED,
    error,
  }
}

export function loginSocialAction(loginSocialRequest) {
  return {
    type: constant.LOGIN_SOCIAL,
    loginSocialRequest,
  }
}

export function loginSocialActionSuccess(payload) {
  return {
    type: constant.LOGIN_SOCIAL_SUCCESS,
    payload,
  }
}

export function loginSocialActionFailed(error) {
  return {
    type: constant.LOGIN_SOCIAL_FAILED,
    error,
  }
}

export function resetPasswordAction(resetPasswordRequest) {
  return {
    type: constant.RESET_PASSWORD,
    resetPasswordRequest,
  }
}

export function resetPasswordActionSuccess(payload) {
  return {
    type: constant.RESET_PASSWORD_SUCCESS,
    payload,
  }
}

export function resetPasswordActionFailed(error, link) {
  return {
    type: constant.RESET_PASSWORD_FAILED,
    error,
    link,
  }
}

export function clearDataResetPassword(error) {
  return {
    type: constant.CLEAR_DATA_FORGOT_PASSWORD,
    error,
  }
}

export function createPassword(payload) {
  return {
    type: constant.CREATE_PASSWORD,
    payload,
  }
}

export function createPasswordSuccess(payload) {
  return {
    type: constant.CREATE_PASSWORD_SUCCESS,
    payload,
  }
}

export function createPasswordFailed(error) {
  return {
    type: constant.CREATE_PASSWORD_FAILED,
    error,
  }
}

import * as constant from './constants'
import { get } from 'lodash'

export const loadSubCategory = (payload) => {
  return {
    type: constant.LOAD_SUB_CATEGORY,
    payload,
  }
}

export const loadSubCategoriesSuccess = (data) => {
  return {
    type: constant.LOAD_SUB_CATEGORY_SUCCESS,
    payload: data,
  }
}

export const loadSubCategoriesFailed = (data) => {
  return {
    type: constant.LOAD_SUB_CATEGORY_FAIL,
    payload: data,
  }
}

export const loadProductCategory = (id) => {
  return {
    type: constant.LOAD_PRODUCT_CATEGORY,
    payload: id,
  }
}

export const saveProductCategory = (data) => {
  return {
    type: constant.SAVE_PRODUCT_CATEGORY,
    payload: data,
  }
}

export const saveProductList = (data, currentPage) => {
  return {
    type: constant.SAVE_PRODUCT_LIST,
    payload: data,
    currentPage: currentPage,
  }
}

export function clearProductList() {
  return {
    type: constant.CLEAR_PRODUCT_LIST,
  }
}

export const saveSlugData = (res) => {
  return async (dispatch) => {
    if (typeof res == 'object') {
      const id = get(res, 'id', '')
      if (id) {
        dispatch({
          type: constant.SAVE_SLUG_DATA,
          payload: res,
        })
      }
    }
  }
}

export function clearSlugData() {
  return {
    type: constant.CLEAR_SLUG_DATA,
  }
}

export const saveFilter = (payload) => {
  return {
    type: constant.SAVE_FILTER,
    payload,
  }
}

export const saveArrange = (payload) => {
  return {
    type: constant.SAVE_ARRANGE,
    payload,
  }
}

export const saveSecondaryFilter = (payload) => {
  return {
    type: constant.SAVE_SECONDARY_FILTER,
    payload,
  }
}

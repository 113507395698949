import { createSelector } from 'reselect'
import { initialState } from './reducer'

const selectCategoryDomain = state => state.categoryView || initialState

export const makeSelectSubCategoryLoading = () =>
  createSelector(selectCategoryDomain, subState => subState.subCategoryLoading)

export const makeSelectSubCategory = () =>
  createSelector(selectCategoryDomain, subState => subState.subCategory)

export const makeSelectProductCategory = () =>
  createSelector(selectCategoryDomain, subState => subState.productCategory)

export const makeSelectProductList = () =>
  createSelector(selectCategoryDomain, subState => subState.productList)

export const makeSelectSlugData = () =>
  createSelector(selectCategoryDomain, subState => subState.slugData)

export const makeSelectFilter = () =>
  createSelector(selectCategoryDomain, subState => subState.filter)

export const makeSelectSecondaryFilter = () =>
  createSelector(selectCategoryDomain, subState => subState.secondaryFilter)

export const makeSelectCurrentPage = () =>
  createSelector(selectCategoryDomain, subState => subState.currentPage)

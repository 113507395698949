const baseUrl = process.env.REACT_APP_API_URL || 'https://staging.landers.ph'

const documentWidth = window.innerWidth || document.documentElement.clientWidth
window.__DEV__ = false

const config = {
  baseUrl,
  apiUrl: baseUrl + '/rest/V1',
  nmsUrl: process.env.REACT_APP_NMS_URL + '/v1',
  npsUrl: process.env.REACT_APP_NPS_URL + '/v1',
  trackingApiUrl: `${
    process.env.REACT_APP_TRACKING_URL || 'https://tps-dev.snapmart.ph'
  }/v1`,
  helpDeskUrl:
    process.env.REACT_APP_HELP_DESK_URL || 'https://helpdesk-dev.snapmart.ph',
  staticPageUrl: baseUrl + '/rest/all/V1/json-page',
  imageUrl:
    process.env.REACT_APP_IMAGE_URL ||
    'https://d5anwn521ljmo.cloudfront.net/catalog/product',
  isWeb: documentWidth >= 768,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '559591571174785',
  googleClientId:
    process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    '626577750313-h4vscbtemfejnall6omirvskb4qoraeu.apps.googleusercontent.com',
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID || 'UA-133445616-4',
  gtmContainerId: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-NH95HCW',
  // Sentry Data Source Name
  sentryDSN:
    process.env.REACT_APP_SENTRY_DSN ||
    'https://34143891b84840129d62a593c0852e31@o407431.ingest.sentry.io/5276353',
  appLinkIOS: 'https://apps.apple.com/us/app/landers-superstore/id1480948945',
  appLinkAndroid:
    'https://play.google.com/store/apps/details?id=com.snapmart.landers&hl=en',
  appLink: {
    domain: process.env.REACT_APP_LINK_DOMAIN || 'https://landersph.page.link',
    apn: process.env.REACT_APP_LINK_APN || 'com.landersph.mobile_dev',
    ibi: process.env.REACT_APP_LINK_IBI || 'com.landersph.mobiledev',
    isi: '1480948945',
    title: 'Tap OPEN below to download app',
    desc:
      "Enter a world of SUPER with Landers Superstore's mobile app! Easily apply for membership online!",
    img: 'https://d5anwn521ljmo.cloudfront.net/content/banner.png',
  },
  reCaptchaKey:
    process.env.REACT_APP_RECAPTCHA_KEY ||
    '6LdPXHQgAAAAANTuPE3UQxEuyPFzJGWsuNn65Ut_',
}

export default config

import get from 'lodash/get'

export const calculateTotalSavingByItems = (cartItems) => {
  if (!Array.isArray(cartItems)) {
    return 0
  }
  return cartItems.reduce((prev, cur) => {
    return (
      prev +
      (get(cur, 'extension_attributes.product_data.price') - cur.price) *
        cur.qty
    )
  }, 0)
}

export const getValueByCode = (segments, code) => {
  if (!Array.isArray(segments)) {
    return 0
  }
  const segment = segments.find((item) => item.code === code)
  return segment ? segment.value : 0
}

export const getDefaultShippingFeeLimit = (shippingFees) => {
  if (!Array.isArray(shippingFees)) {
    return 0
  }
  const fee = shippingFees.find((item) => item.total === 0)
  return fee ? fee.shipping_fee : 0
}

export const checkCartHasOnlyMC = (items) =>
  items.every(({ product_type }) => product_type === 'customer_membership')

export const calculateTotalSavings = (
  defaultShippingFee,
  cartData,
  cartTotals
) => {
  const totalSegments = get(cartTotals, 'total_segments') || []
  const cartItems = get(cartData, 'items') || []
  const total_saved_by_items = calculateTotalSavingByItems(cartItems)
  const cartHasOnlyMC = checkCartHasOnlyMC(cartItems)
  const promoDiscountAmount = getValueByCode(
    totalSegments,
    'lander_promotion_amount'
  )
  const personalShoppingFee = getValueByCode(totalSegments, 'fee')
  const discountAmount = get(cartTotals, 'discount_amount', 0)
  const solaneItemsOnly = get(
    totalSegments.find((x) => x.code === 'all_solane_item'),
    'value',
    false
  )

  const originalPersonalShoppingFee = !cartHasOnlyMC
    ? getValueByCode(totalSegments, 'base_fee')
    : 0
  const shippingAmount = cartHasOnlyMC
    ? defaultShippingFee
    : get(cartTotals, 'shipping_amount', 0)

  const finalShippingFee = Math.max(defaultShippingFee - shippingAmount, 0)
  const finalPersonalShopperFee =
    originalPersonalShoppingFee - personalShoppingFee
  if (solaneItemsOnly) {
    return total_saved_by_items - discountAmount + promoDiscountAmount
  }
  return (
    total_saved_by_items -
    discountAmount +
    promoDiscountAmount +
    finalShippingFee +
    finalPersonalShopperFee
  )
}

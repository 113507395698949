// eslint-disable-next-line
import produce from 'immer'

import * as constants from './constants'
import { get } from 'lodash'

export const initialState = {
  showDeliveryLocation: false,
  showProductDetail: false,
  showLogin: false,
  showForgotPassword: false,
  showAddUpdateAddress: false,
  showInvalidCartItems: false,
  showRequestAddMembership: false,
  showCartNotification: false,
  showRemoveAllCartItems: false,
  showRemoveCartItems: false,
  isSignUp: true,
  showMembership: false,
  showShoppingList: false,
  faqForm: false,
  addedProduct: {},
  showPickUpConfirmation: false,
  showAppPromotion: false,
  showSplashAd: false,
  showNewMCExpired: false,
  membershipPayload: null,
  showSubscribeSuccess: false,
  tracking: {},
  currentPopup: null,
  showNetPromotersScoreSurvey: false,
  netPromotersScorePayload: null,
  showMembershipCard: false,
  showDeliveryAddress: false,
  showVoucherTermCondition: false,
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // SHOW_DELIVERY_LOCATION
      case constants.SHOW_DELIVERY_LOCATION:
        newState.showDeliveryLocation = true
        newState.addedProduct = action.addedProduct
        break
      case constants.HIDE_DELIVERY_LOCATION:
        newState.showDeliveryLocation = false
        newState.addedProduct = {}
        break

      // SHOW_PRODUCT_DETAIL
      case constants.SHOW_PRODUCT_DETAIL:
        newState.tracking = action.tracking
        break
      case constants.SHOW_PRODUCT_DETAIL_DONE:
        newState.showProductDetail = true
        break
      case constants.HIDE_PRODUCT_DETAIL:
        newState.tracking = {}
        newState.showProductDetail = false
        break

      // SHOW_LOGIN
      case constants.SHOW_LOGIN:
        newState.showLogin = action.params
        break
      case constants.HIDE_LOGIN:
        newState.showLogin = false
        break

      // SHOW_FORGOT_PASSWORD
      case constants.SHOW_FORGOT_PASSWORD:
        newState.showForgotPassword = action.params
        break
      case constants.HIDE_FORGOT_PASSWORD:
        newState.showForgotPassword = false
        break

      // SHOW_ADDUPDATE_ADDRESS
      case constants.SHOW_ADDUPDATE_ADDRESS:
        newState.showAddUpdateAddress = action.initAddress
        break
      case constants.HIDE_ADDUPDATE_ADDRESS:
        newState.showAddUpdateAddress = false
        break

      // SHOW_INVALID_CARTITEMS
      case constants.SHOW_INVALID_CARTITEMS:
        if (action.invalidSkus) {
          newState.showInvalidCartItems = action.invalidSkus
        } else {
          newState.showInvalidCartItems = true
        }
        break
      case constants.HIDE_INVALID_CARTITEMS:
        newState.showInvalidCartItems = false
        break

      // REQUEST ADD MEMBERSHIP MODAL
      case constants.SHOW_REQUEST_ADD_MEMBERSHIP:
        newState.showRequestAddMembership = true
        break
      case constants.HIDE_REQUEST_ADD_MEMBERSHIP:
        newState.showRequestAddMembership = false
        break

      // REQUEST ADD MEMBERSHIP MODAL
      case constants.SHOW_CART_NOTIFICATION_MODAL:
        newState.showCartNotification = action.info
        break
      case constants.HIDE_CART_NOTIFICATION_MODAL:
        newState.showCartNotification = false
        break

      // SHOW_REMOVE_ALL_CART_ITEMS
      case constants.SHOW_REMOVE_ALL_CART_ITEMS:
        newState.showRemoveAllCartItems = true
        break
      case constants.HIDE_REMOVE_ALL_CART_ITEMS:
        newState.showRemoveAllCartItems = false
        break

      // SHOW_REMOVE_CART_ITEMS
      case constants.SHOW_REMOVE_CART_ITEMS:
        newState.showRemoveCartItems = action.cartItems
        break
      case constants.HIDE_REMOVE_CART_ITEMS:
        newState.showRemoveCartItems = false
        break

      // SHOW_ALERT
      case constants.SHOW_ALERT:
        newState.showAlert = action.alertOptions
        break
      case constants.HIDE_ALERT:
        newState.showAlert = false
        break

      case constants.CHANGE_TAB_SIGN_UP:
        newState.isSignUp = action.payload
        break

      // REQUEST SHOW MEMBERSHIP MODAL
      case constants.SHOW_MEMBERSHIP_MODAL:
        newState.showMembership = action.params
        break
      case constants.HIDE_MEMBERSHIP_MODAL:
        newState.showMembership = false
        break
      // SHOW MODAL INFORM MEMBERSHIP IS EXPIRED
      case constants.SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED:
        newState.showInformMembershipIsExpiredModal = true
        break
      case constants.HIDE_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED:
        newState.showInformMembershipIsExpiredModal = false
        break

      // SHOPPING_LIST
      case constants.SHOW_SHARE_SHOPPING_LIST:
        newState.showShoppingList = action.params
        break

      case constants.SHOW_CREATE_SHOPPING_LIST:
        newState.showShoppingList = action.params
        break

      case constants.SHOW_EDIT_SHOPPING_LIST:
        newState.showShoppingList = action.params
        break

      case constants.SHOW_ADD_PRODUCT_TO_SHOPPING_LIST:
        newState.showShoppingList = action.params
        break

      case constants.HIDE_MODAL_SHOPPING_LIST:
        newState.showShoppingList = false
        break

      case constants.SHOW_MODAL_FAQ_SUCCESS:
        newState.faqForm = {
          success: true,
          error: false,
          errorMessage: '',
        }
        break
      case constants.SHOW_MODAL_FAQ_FAILED:
        newState.faqForm = {
          success: false,
          error: true,
          errorMessage:
            get(action, 'error.message') ||
            'We encountered an error, please try again later.',
        }
        break
      case constants.HIDE_MODAL_FAQ:
        newState.faqForm = false
        break
      case constants.SHOW_PICKUP_CONFIRMATION:
        newState.showPickUpConfirmation = true
        break
      case constants.HIDE_PICKUP_CONFIRMATION:
        newState.showPickUpConfirmation = false
        break
      case constants.SHOW_APP_PROMOTION_MODAL:
        newState.showAppPromotion = true
        break
      case constants.HIDE_APP_PROMOTION_MODAL:
        newState.showAppPromotion = false
        break
      case constants.SHOW_SPLASH_AD_MODAL:
        newState.showSplashAd = true
        newState.currentPopup = action.payload
        break
      case constants.HIDE_SPLASH_AD_MODAL:
        newState.showSplashAd = false
        newState.currentPopup = null
        break
      case constants.SHOW_NEW_MC_EXPIRED_MODAL:
        newState.showNewMCExpired = true
        newState.membershipPayload = action.params
        break
      case constants.HIDE_NEW_MC_EXPIRED_MODAL:
        newState.showNewMCExpired = false
        newState.membershipPayload = null
        break
      case constants.SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL:
        newState.showNetPromotersScoreSurvey = true
        newState.netPromotersScorePayload = action.payload
        break
      case constants.HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL:
        newState.showNetPromotersScoreSurvey = false
        newState.netPromotersScorePayload = null
        break
      case constants.SHOW_MEMBERSHIP_CARD_MODAL:
        newState.showMembershipCard = action.params
        break
      case constants.HIDE_MEMBERSHIP_CARD_MODAL:
        newState.showMembershipCard = false
        break
      case constants.SHOW_DELIVERY_ADDRESS_MODAL:
        newState.showDeliveryAddress = {
          isOpen: true,
          isPickup: action.params.isPickup,
        }
        newState.addedProduct = action.params.addedProduct
        break
      case constants.HIDE_DELIVERY_ADDRESS_MODAL:
        newState.showDeliveryAddress = null
        newState.addedProduct = {}
        break
      case constants.SHOW_VOUCHER_TERM_CONDITION_MODAL:
        newState.showVoucherTermCondition = action.params
        break
      case constants.HIDE_VOUCHER_TERM_CONDITION_MODAL:
        newState.showVoucherTermCondition = false
        break
      case constants.SHOW_SUBSCRIBE_SUCCESS_MODAL:
        newState.showSubscribeSuccess = true
        break
      case constants.HIDE_SUBSCRIBE_SUCCESS_MODAL:
        newState.showSubscribeSuccess = false
        break
    }
  })

export default reducer

export const LOAD_RELATED_PRODUCT = 'landers/productDetail/LOAD_RELATED_PRODUCT'
export const LOAD_RELATED_PRODUCT_SUCCESS =
  'landers/productDetail/LOAD_RELATED_PRODUCT_SUCCESS'
export const LOAD_RELATED_PRODUCT_FAILED =
  'landers/productDetail/LOAD_RELATED_PRODUCT_FAILED'

export const LOAD_PRODUCT_DETAIL = 'landers/productDetail/LOAD_PRODUCT_DETAIL'
export const LOAD_PRODUCT_DETAIL_SUCCESS =
  'landers/productDetail/LOAD_PRODUCT_DETAIL_SUCCESS'
export const LOAD_PRODUCT_DETAIL_FAILED =
  'landers/productDetail/LOAD_PRODUCT_DETAIL_FAILED'

export const ADD_REVIEW_START = 'landers/productDetail/ADD_REVIEW_START'
export const ADD_REVIEW_FINISH = 'landers/productDetail/ADD_REVIEW_FINISH'

export const ADD_PRODUCT_REVIEW = 'landers/productDetail/ADD_PRODUCT_REVIEW'
export const ADD_PRODUCT_REVIEW_SUCCESS =
  'landers/productDetail/ADD_PRODUCT_REVIEW_SUCCESS'
export const ADD_PRODUCT_REVIEW_FAILED =
  'landers/productDetail/ADD_PRODUCT_REVIEW_FAILED'

export const LOAD_SHOPPING_LIST_ADDED =
  'landers/productDetail/LOAD_SHOPPING_LIST_ADDED'
export const LOAD_SHOPPING_LIST_ADDED_SUCCESS =
  'landers/productDetail/LOAD_SHOPPING_LIST_ADDED_SUCCESS'
export const LOAD_SHOPPING_LIST_ADDED_FAILED =
  'landers/productDetail/LOAD_SHOPPING_LIST_ADDED_FAILED'

/*
 *
 * AuthLogin constants
 *
 */

// SEARCH in header
export const SEARCH_HEADER = 'landers/Search/SEARCH_HEADER'
export const SEARCH_HEADER_SUCCESS = 'landers/Search/SEARCH_HEADER_SUCCESS'
export const SEARCH_HEADER_FAILED = 'landers/Search/SEARCH_HEADER_FAILED'
export const RESET_SEARCH = 'landers/Search/RESET_SEARCH'

// SEARCH RESULTS
export const LOAD_SEARCH_RESULTS = 'landers/Search/LoadSearchResults'
export const LOAD_SEARCH_RESULTS_SUCCESS =
  'landers/Search/LoadSearchResultsSuccess'
export const LOAD_SEARCH_RESULTS_FAILED =
  'landers/Search/LoadSearchResultsFailed'

export const LOAD_LIST_CATEGORY_SEARCH =
  'landers/Search/LOAD_LIST_CATEGORY_SEARCH'
export const SAVE_LIST_CATEGORY_SEARCH =
  'landers/Search/SAVE_LIST_CATEGORY_SEARCH'

export const selectList = [
  {
    label: 'Popularity',
    value: null,
    direction: 'ASC',
  },
  {
    label: 'Price - Low to High',
    value: 'price',
    direction: 'ASC',
  },
  {
    label: 'Price - High to Low',
    value: 'price',
    direction: 'DESC',
  },
  {
    label: 'Name',
    value: 'name',
    direction: 'ASC',
  },
  {
    label: 'Created date',
    value: 'entity_id',
    direction: 'ASC',
  },
]
export const filterList = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'On Sale',
    value: 'onsale',
  },
  {
    label: 'In Stock',
    value: 'instock',
  },
]
export const HOT_OFFERS_OPTIONS = [
  {
    label: 'Popularity',
    value: 'lander_store_quantity',
  },
  {
    label: 'Price - Low to High',
    value: 'price+',
  },
  {
    label: 'Price - High to Low',
    value: 'price-',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Created date',
    value: 'entity_id',
  },
]
export const HOT_OFFERS_FILTERS = [
  {
    label: 'All',
    value: 'quantity',
  },
  {
    label: 'New',
    value: 'new',
  },
  {
    label: 'On Sale',
    value: 'onsale',
  },
  {
    label: 'In Stock',
    value: 'instock',
  },
]

import * as constant from './constants'

export const loadRelatedProduct = (payload) => {
  return {
    type: constant.LOAD_RELATED_PRODUCT,
    payload,
  }
}

export const saveRelatedProduct = (sku) => {
  return {
    type: constant.LOAD_RELATED_PRODUCT_SUCCESS,
    payload: sku,
  }
}

export const saveProductDetail = (payload) => {
  return {
    type: constant.LOAD_PRODUCT_DETAIL_SUCCESS,
    payload,
  }
}

export const addProductReview = (payload, resetForm) => ({
  type: constant.ADD_PRODUCT_REVIEW,
  payload,
  resetForm,
})

export const addProductReviewSuccess = (payload) => ({
  type: constant.ADD_PRODUCT_REVIEW_SUCCESS,
  payload,
})

export const addProductReviewFailed = (error) => ({
  type: constant.ADD_PRODUCT_REVIEW_FAILED,
  error,
})

export const loadShoppingListAdded = (payload) => {
  return {
    type: constant.LOAD_SHOPPING_LIST_ADDED,
    payload,
  }
}

export const saveShoppingListAdded = (payload) => {
  return {
    type: constant.LOAD_SHOPPING_LIST_ADDED_SUCCESS,
    payload,
  }
}

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import config from 'global-config'
import request from 'utils/request'
import { showError, showSuccess } from 'utils/notification'
import * as constants from './constants'
import * as actions from './actions'

function* onGetCollectibleVouchers(action) {
  const coupon_category = action.params.coupon_category
  const page = action.params?.page || 1
  const page_size = action?.params?.page_size || 200
  const exclude_claimed = action?.params?.exclude_claimed || false

  try {
    let requestURL = `${config.apiUrl}/coupon-collectible/list?coupon_category=${coupon_category}&current_page=${page}&page_size=${page_size}`

    if (exclude_claimed) {
      requestURL = requestURL + `&exclude_claimed=true`
    }

    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(
      actions.getCollectibleVouchersSuccess({
        coupon_category,
        assigned_page: page,
        items: res.map((voucher) => ({
          ...voucher,
          assigned_page: page,
          is_claimed: exclude_claimed ? false : true,
          is_processing: false,
        })),
        hasMoreItems: res.length < page_size ? false : true,
      })
    )
  } catch (err) {
    showError(`Failed to fetch collectible vouchers!`)
    yield put(actions.getCollectibleVouchersFailed(err))
  }
}

function* onGetClaimedVouchers(action) {
  const coupon_category = action.payload.coupon_category
  const page = action.payload?.page || 1
  const page_size = action.payload?.page_size || 200

  try {
    const requestURL = `${config.apiUrl}/coupon-collectible/get?coupon_category=${coupon_category}&current_page=${page}&page_size=${page_size}`
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(
      actions.getClaimedVouchersSuccess({
        coupon_category,
        assigned_page: page,
        items: res.map((voucher) => ({
          ...voucher,
          is_claimed: true,
          assigned_page: page,
          is_processing: false,
        })),
        hasMoreItems: res.length < page_size ? false : true,
      })
    )
  } catch (err) {
    showError(`Failed to fetch claimed vouchers!`)
    yield put(actions.getClaimedVouchersFailed(err))
  }
}

function* onSaveClaimVoucher(action) {
  const coupon_category = action.payload.coupon_category
  const voucher = action.payload.claimedVoucher

  try {
    yield put(
      actions.setVoucherIsProcessing({
        ...voucher,
        is_processing: true,
      })
    )

    const requestURL = `${config.apiUrl}/coupon-collectible/save`

    yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify({ code: voucher.code }),
    })

    yield put(
      actions.saveClaimedVoucherSuccess({
        coupon_category,
        item: voucher,
      })
    )

    yield showSuccess(`Successfully claimed voucher: ${voucher.rule_name}`)
  } catch (err) {
    showError(`Failed to claim voucher: ${voucher.rule_name}`)
  } finally {
    yield put(
      actions.setVoucherIsProcessing({
        ...voucher,
        is_processing: false,
      })
    )
  }
}

export default function* accountSaga() {
  yield takeEvery(constants.GET_VOUCHERS_COLLECTIBLE, onGetCollectibleVouchers)
  yield takeEvery(constants.GET_VOUCHERS_CLAIMED, onGetClaimedVouchers)
  yield takeEvery(constants.SAVE_CLAIM_VOUCHER, onSaveClaimVoucher)
}

import config from 'global-config'
import { SCREEN } from 'global-constant'
import { delay, call, put, takeLatest, select } from 'redux-saga/effects'
import request from 'utils/request'
import * as globalActions from 'containers/Landers/actions'
import * as actions from './actions'
import * as constants from './constants'
import { get } from 'lodash'
import * as modalActions from 'containers/Modals/actions'
import * as navigation from 'utils/navigation'
import { showError, showSuccess } from 'utils/notification'
import { history } from 'configureStore'
import urls from 'constant/urls'
import { makeSelectCurrentUser } from 'containers/Landers/selectors'
import locationServices from 'services/location'
import { onGetUserInfo } from 'containers/Landers/saga'

const __DEV__ = process.env.NODE_ENV !== 'production'

function* onGetAllOrders(action) {
  const { page = 1, pageSize = 5, sortOrders = 'created_at' } = action.payload
  const requestURL = `${config.apiUrl}/orders/mine?searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${page}&searchCriteria[sortOrders][1][field]=${sortOrders}&searchCriteria[sortOrders][1][direction]=DESC`
  yield put(globalActions.showLoader())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.getAllOrdersSuccess(res))
    yield put(globalActions.hideLoader())
  } catch (err) {
    yield put(actions.getAllOrdersFailed(err))
    yield put(globalActions.hideLoader())
  }
}

function* onGetCurrentOrders(action) {
  const { page = 1, pageSize = 5, sortOrders = 'created_at' } = action.payload
  const requestURL = `${config.apiUrl}/orders/mine?searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${page}&searchCriteria[sortOrders][1][field]=${sortOrders}&searchCriteria[sortOrders][1][direction]=DESC`
  yield put(globalActions.showLoader())
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.getCurrentOrdersSuccess(res))
    yield put(globalActions.hideLoader())
  } catch (err) {
    console.log('err:', err)
    yield put(actions.getCurrentOrdersFailed(err))
    yield put(globalActions.hideLoader())
  }
}

function* onCreateAddress(action) {
  const isPickup = action.isPickup
  try {
    const response = yield call(
      [locationServices, 'createCustomerAddress'],
      action.address
    )
    yield put(actions.createAddressSuccess(response))
    yield put(globalActions.getUserInfo())
    yield delay(500)
    const currentUser = yield select((state) => makeSelectCurrentUser()(state))
    const userAddresses = currentUser.addresses || []
    const validAddress = userAddresses.find(
      (address) =>
        address.id === response.id &&
        get(address, 'extension_attributes.address_validation_data.source_code')
    )
    if (validAddress && get(action, 'address.address.default_shipping')) {
      yield put(globalActions.checkLocationSuccess(validAddress))
    }
    if (!isPickup) {
      yield put(actions.clearStatusEditAbleAddress())
      showSuccess('Address successfully created')
      history.push(urls.addressBook)
    } else {
      yield put(globalActions.checkLocationSuccess(validAddress))
    }
  } catch (err) {
    showError(get(err, 'message') || 'Address create failed')
    yield put(actions.createAddressFailed(err))
    yield put(actions.clearStatusEditAbleAddress())
  }
}

function* onUpdateAddress(action) {
  const isPickup = action.isPickup
  try {
    const response = yield call(
      [locationServices, 'updateCustomerAddress'],
      action.address
    )
    yield put(modalActions.hideAddUpdateAddressModal())
    yield put(actions.updateAddressSuccess(response))
    const currentUser = yield call(onGetUserInfo, { needValidateAddress: true })
    const userAddresses = currentUser?.addresses || []

    const validAddress = userAddresses.find(
      (address) =>
        address.id === get(action, 'address.address.id') &&
        get(address, 'extension_attributes.address_validation_data.source_code')
    )
    if (validAddress && get(action, 'address.address.default_shipping')) {
      yield put(globalActions.checkLocationSuccess(validAddress))
    }
    if (!isPickup) {
      yield put(actions.clearStatusEditAbleAddress())
      showSuccess('Address successfully updated')
      !get(action, 'address.noRedirect', false) &&
        history.push(urls.addressBook)
    } else {
      yield put(globalActions.checkLocationSuccess(validAddress))
    }
  } catch (err) {
    showError(get(err, 'message') || 'Address update failed')
    yield put(actions.updateAddressFailed(err))
    yield put(actions.clearStatusEditAbleAddress())
  }
}

function* onUpdateUserInfo(action) {
  const requestURL = `${config.apiUrl}/customers/me`
  try {
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(action.userInfo),
    })

    if (action.isChangeEmail) {
      yield put(actions.updateEmail(action.emailPayload))
    }
    if (action.isChangePassword) {
      yield put(actions.updatePassword(action.passwordPayload))
    }
    yield put(actions.updateUserInfoSuccess(res))

    // reload data when update last name or first name
    if (!action.isChangeEmail && !action.isChangePassword) {
      yield put(
        globalActions.getUserInfo({
          needValidateAddress: true,
        })
      )
      yield showSuccess(
        'Your account information has been successfully updated'
      )
      navigation.navigate(SCREEN.ACCOUNT)
    }
  } catch (err) {
    err.json().then((errorJson) => {
      if (__DEV__) {
        console.log(errorJson)
      }
      const errMsg = get(errorJson, 'message')
      if (errMsg) {
        showError(errMsg)
      }
    })
    yield put(actions.updateUserInfoFailed(err))
  }
}

function* onUpdateEmail(action) {
  const requestURL = `${config.apiUrl}/ld-customers/me/email`
  try {
    yield put(globalActions.showLoader())
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(action.payload),
    })
    yield put(actions.updateEmailSuccess(res))
    yield put(
      globalActions.getUserInfo({
        needValidateAddress: true,
      })
    )
    yield showSuccess('Your account information has been successfully updated')
    navigation.navigate(SCREEN.ACCOUNT)
  } catch (err) {
    err.json().then((errorJson) => {
      if (__DEV__) {
        console.log(errorJson)
      }
      const errMsg = get(errorJson, 'message')
      if (errMsg) {
        showError(errMsg)
      }
    })
    yield put(actions.updateEmailFailed(err))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* onUpdatePassword(action) {
  const requestURL = `${config.apiUrl}/ld-customers/me/password`
  try {
    yield put(globalActions.showLoader())
    const res = yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(action.payload),
    })
    yield put(actions.updatePasswordSuccess(res))
    yield showSuccess('Your account information has been successfully updated')
    navigation.navigate(SCREEN.ACCOUNT)
  } catch (err) {
    err.json().then((errorJson) => {
      if (__DEV__) {
        console.log(errorJson)
      }
      const errMsg = get(errorJson, 'message')
      if (errMsg) {
        showError(errMsg)
      }
    })
    yield put(actions.updatePasswordFailed(err))
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* onGetPriceSubscriptions(action) {
  const requestURL = `${config.apiUrl}/price-subscriptions`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.getPriceSubscriptionsSuccess(res))
  } catch (err) {
    yield put(actions.getPriceSubscriptionsFailed(err))
  }
}

function* onDeletePriceSubscriptions(action) {
  const { id } = action.payload
  const requestURL = `${config.apiUrl}/price-subscriptions/${id}`

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    })
    yield put(actions.getPriceSubscriptions())
  } catch (err) {
    console.log('err:', err)
    yield put(actions.deletePriceSubscriptionsFailed(err))
  }
}

function* onGetStockSubscriptions(action) {
  const requestURL = `${config.apiUrl}/out-of-stock-subscriptions`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(actions.getStockSubscriptionsSuccess(res))
  } catch (err) {
    yield put(actions.getStockSubscriptionsFailed(err))
  }
}

function* onDeleteStockSubscriptions(action) {
  const { id } = action.payload
  const requestURL = `${config.apiUrl}/out-of-stock-subscriptions/${id}`

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
    })
    yield put(actions.getStockSubscriptions())
  } catch (err) {
    console.log('err:', err)
    yield put(actions.deleteStockSubscriptionsFailed(err))
  }
}

function* onLoadProductReview(action) {
  const requestURL = `${config.apiUrl}/review/mine`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.saveProductReview(res[0].reviews))
  } catch (err) {}
}

// Individual exports for testing
export default function* accountSaga() {
  yield takeLatest(constants.GET_All_ORDERS, onGetAllOrders)
  yield takeLatest(constants.GET_CURRENT_ORDERS, onGetCurrentOrders)
  yield takeLatest(constants.CREATE_ADDRESS, onCreateAddress)
  yield takeLatest(constants.UPDATE_ADDRESS, onUpdateAddress)
  yield takeLatest(constants.UPDATE_USER_INFO, onUpdateUserInfo)
  yield takeLatest(constants.UPDATE_EMAIL, onUpdateEmail)
  yield takeLatest(constants.UPDATE_PASSWORD, onUpdatePassword)
  yield takeLatest(constants.GET_PRICE_SUBSCRIPTIONS, onGetPriceSubscriptions)
  yield takeLatest(
    constants.DELETE_PRICE_SUBSCRIPTIONS,
    onDeletePriceSubscriptions
  )
  yield takeLatest(constants.GET_STOCK_SUBSCRIPTIONS, onGetStockSubscriptions)
  yield takeLatest(
    constants.DELETE_STOCK_SUBSCRIPTIONS,
    onDeleteStockSubscriptions
  )
  yield takeLatest(constants.LOAD_PRODUCT_REVIEW, onLoadProductReview)
}

import { combineReducers } from 'redux'

import homeReducer from 'containers/Home/reducer'
import sideMenuReducer from './component/sideMenu/sideMenuReducer'
import loginReducer from './component/login/loginReducer'
import authModalReducer from './component/authModal/authModalReducer'
import videoModalReducer from './component/videoModal/videoModalReducer'
import sliderReducer from './component/slider/sliderReducer'
import BreadCrumbReducer from './component/BreadCrumb/reducer'
import { connectRouter } from 'connected-react-router'
import signupReducer from 'component/signup/reducer/signupReducer'

// Migration from react native
import rncheckoutReducer from 'containers/Checkout/reducer'
import rnglobalReducer from 'containers/Landers/reducer'
import rnmodalReducer from 'containers/Modals/reducer'
import rnsearchReducer from 'containers/Search/reducer'
import rnaccountReducer from 'containers/Account/reducer'
import rnauthenReducer from 'containers/Authentication/reducer'
import rnmembershipReducer from 'containers/Membership/reducer'

// refactor component to container
import shoppingListReducer from 'containers/ShoppingList/reducer'
import productItemReducer from './containers/ProductDetail/reducer'
import categoryReducer from './containers/Category/reducer'
import theLandersExperienceReducer from 'containers/TheLandersExperience/reducer'
import trackAndTraceReducer from 'containers/TrackAndTrace/reducer'
import deliveryAreasReducer from 'containers/DeliveryAreas/reducer'
import helpReducer from 'containers/Help/reducer'

import voucherReducer from 'containers/Vouchers/reducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    home: homeReducer,
    sideMenu: sideMenuReducer,
    login: loginReducer,
    authModal: authModalReducer,
    videoModal: videoModalReducer,
    productDetail: productItemReducer,
    categoryView: categoryReducer,
    slider: sliderReducer,

    breadcrumb: BreadCrumbReducer,
    shoppingList: shoppingListReducer,
    signup: signupReducer,
    // delyvery location

    // Migrations from react-native
    rnglobal: rnglobalReducer,
    rncheckout: rncheckoutReducer,
    rnmodals: rnmodalReducer,
    rnsearch: rnsearchReducer,
    rnaccount: rnaccountReducer,
    rnauthen: rnauthenReducer,
    rnmembership: rnmembershipReducer,

    // page
    theLandersExp: theLandersExperienceReducer,
    trackAndTrace: trackAndTraceReducer,
    deliveryAreas: deliveryAreasReducer,
    help: helpReducer,

    voucher: voucherReducer,
  })

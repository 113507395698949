import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { ID_PAST_PURCHASES_LIST, SCREEN, SCREEN_TITLE } from 'global-constant'
import { isMobile } from 'react-device-detect'

export const trackingEvent = {
  addToCart: 'add_to_cart',
  splashAds: 'splash_ads',
  clickNotification: 'click_notification',
  clickReorder: 'click_reorder',
  purchase: 'purchase',
  removeFromCart: 'remove_from_cart',
  clickSearch: 'click_search',
  searchKey: 'search_key',
  clickCart: 'click_cart',
  viewMiniCart: 'view_mini_cart',
  removeFromCart: 'remove_from_cart',
  clickEmptyCart: 'click_empty_cart',
  clickCheckout: 'click_checkout',
  clickApplyMembership: 'click_apply_membership',
  linkMembership: 'link_membership',
  clickBanner: 'click_banner',
  signupCompleted: 'signup_completed',
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const initialFirebase = () => {
  return initializeApp(firebaseConfig)
}
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export const getUserToken = () => {
  return new Promise((resolve, reject) =>
    getToken(getMessaging(), {
      vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH_TOKEN,
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken)
        } else {
          reject(false)
        }
      })
      .catch((err) => {
        reject(err)
      })
  )
}

export const getProductsFromOrder = (items) => {
  if (!Array.isArray(items)) {
    return []
  }
  return items.map((item) => ({
    item_name: item.name,
    item_id: `${item.product_id}`,
    price: item.price,
    quantity: item.qty_ordered,
    item_category: '',
    item_brand: '',
  }))
}

export function trackEvent(event, data) {
  const payload = {
    time: new Date().getTime(),
    platform_web: isMobile ? 'msite' : 'desktop',
    ...data,
  }
  logEvent(analytics, event, payload)
}

export function trackScreen(data) {
  const payload = {
    platform_web: isMobile ? 'msite' : 'desktop',
    ...data,
  }
  logEvent(analytics, 'screen_view', payload)
}

export function pageTracking(routeName, params) {
  if (routeName === SCREEN.CATEGORY) {
    if (params?.categoryName) {
      trackScreen({
        firebase_screen: params?.categoryName,
        firebase_screen_class: routeName,
      })
    }
  } else if (routeName === SCREEN.PROMOTION) {
    if (params?.title) {
      trackScreen({
        firebase_screen: params?.title,
        firebase_screen_class: routeName,
      })
    }
  } else if (
    routeName === SCREEN.SHOPPINGLISTDETAIL &&
    params?.listId === ID_PAST_PURCHASES_LIST
  ) {
    trackScreen({
      firebase_screen: 'Past Purchases',
      firebase_screen_class: routeName,
    })
  } else if (routeName === SCREEN.ORDER_DETAIL) {
    trackScreen({
      firebase_screen: `Order # ${params?.order?.increment_id}`,
      firebase_screen_class: routeName,
    })
  } else if (routeName === SCREEN.SEARCHRESULT) {
    trackScreen({
      firebase_screen: `Search result for: ${params?.keyword}`,
      firebase_screen_class: routeName,
    })
  } else {
    const routeTitle = SCREEN_TITLE[routeName]
    if (!!routeTitle) {
      trackScreen({
        firebase_screen: routeTitle,
        firebase_screen_class: routeName,
      })
    }
  }
}

import { call, put, select, takeLatest } from 'redux-saga/effects'
import request from 'utils/request'
import { get, isEmpty } from 'lodash'
import * as actions from './actions'
import * as constant from './constants'
import { hideModalShoppingList } from 'containers/Modals/actions'
import * as globalActions from 'containers/Landers/actions'
import * as ShoppingListSelectors from 'containers/ShoppingList/selectors'
import { showError, showSuccess } from 'utils/notification'
import queryString from 'qs'
import config from 'global-config'

const { apiUrl } = config

const API_CREATE_UPDATE_LIST = `${apiUrl}/crud-shopping-list/mine`
const API_GET_MY_SHOPPING_LIST = `${apiUrl}/get-shopping-lists/mine`
const API_GET_ALL_PRODUCTS_IN_MY_LIST = `${apiUrl}/all-products-list/mine`
const API_GET_MY_PAST_PURCHASE = `${apiUrl}/past-purchase/mine`
const API_GET_DETAIL_OF_LIST = `${apiUrl}/get-detail-list/mine/:listId`
const API_DELETE_SHOPPING_LIST_BY_LIST_ID = `${apiUrl}/crud-shopping-list/mine/:listId`
const API_DELETE_ITEM_FROM_SHOPPING_LIST = `${apiUrl}/crud-product-to-list/mine/:listId/:productId`
const API_SHARE_MY_DATA_OF_LIST_BY_LIST_ID = `${apiUrl}/crud-share-data-list/mine/:listId`
const API_GET_SHARE_MY_DATA_OF_LIST_BY_LIST_ID = `${apiUrl}/get-share-data-list/mine/:listId`
const API_GET_DETAIL_OF_LIST_BY_TOKEN = `${apiUrl}/get-share-list-by-token/:listId/:token`
const API_ADD_SUBSCRIBE_SALE_FOR_PRODUCT = `${apiUrl}/crud-subscribe-sale/mine/:productId`
const API_DELETE_SUBSCRIBE_SALE_FOR_PRODUCT = `${apiUrl}/crud-subscribe-sale/mine/:productId`
const API_ADD_TO_MY_LIST = `${apiUrl}/add-to-my-list/:listId/:token`

function* requestCreateUpdateList(action) {
  try {
    yield put(actions.setCreateUpdateListLoading())
    const data = yield call(request, API_CREATE_UPDATE_LIST, {
      method: 'POST',
      body: JSON.stringify({ data: action.payload }),
    })
    let newData = yield select((state) =>
      ShoppingListSelectors.makeSelectDataShoppingList()(state)
    )
    let shoppingListDetail = yield select((state) =>
      ShoppingListSelectors.makeSelectDataShoppingListDetail()(state)
    )
    if (action.payload.list_id) {
      // flow update, update state data and no call api reload
      newData = newData.map((item) => {
        if (item.list_id === data.list_id) {
          return {
            ...item,
            ...action.payload,
          }
        } else {
          return { ...item }
        }
      })
      yield showSuccess('List has been updated')

      if (!isEmpty(shoppingListDetail)) {
        // reload dropdown in shoppinglist detail
        shoppingListDetail.all_lists = get(
          shoppingListDetail,
          'all_lists',
          []
        ).map((item) => {
          if (item.id === data.list_id) {
            return {
              ...item,
              list_name: data.name,
            }
          }
          return item
        })
        yield put(
          actions.saveListDetail({
            ...shoppingListDetail,
            ...action.payload,
          })
        )
      }
    } else {
      // flow create, update state data and no call api reload
      newData.push(data)
      yield showSuccess('A new list has been created')
    }
    yield put(actions.saveShoppingList([...newData]))
    // close modal
    yield put(hideModalShoppingList())
  } catch (err) {
    const errorData = yield err.json()
    yield put(actions.requestCreateUpdateListError(errorData.message))
  } finally {
    yield put(actions.closeCreateUpdateListLoading())
  }
}

function* onLoadShoppingList() {
  try {
    yield put(actions.setShoppingListLoading())
    const data = yield call(request, API_GET_MY_SHOPPING_LIST, {
      method: 'GET',
    })
    if (Array.isArray(data)) {
      yield put(actions.saveShoppingList(data))
    }
    return data
  } catch (err) {
  } finally {
    yield put(actions.closeShoppingListLoading())
  }
}

function* onLoadAllProductInMyList() {
  try {
    const res = yield call(request, API_GET_ALL_PRODUCTS_IN_MY_LIST, {
      method: 'GET',
    })
    if (typeof res === 'object') {
      yield put(actions.saveAllProductInMyList(res))
    }
  } catch (err) {
  } finally {
  }
}

function* onLoadPastPurchase() {
  try {
    const res = yield call(request, API_GET_MY_PAST_PURCHASE, {
      method: 'GET',
    })
    if (typeof res === 'object') {
      yield put(actions.savePastPurchase(res))
    }
  } catch (err) {
  } finally {
  }
}

function* onLoadListDetail(action) {
  let listId = get(action, 'payload.listId')
  const token = get(
    queryString.parse(window.location.search, { ignoreQueryPrefix: true }),
    'tk'
  )
  let requestUrl = token
    ? API_GET_DETAIL_OF_LIST_BY_TOKEN.replace(':listId', listId).replace(
        ':token',
        token
      )
    : API_GET_DETAIL_OF_LIST.replace(':listId', listId)
  // let filter = yield select(state => ShoppingListSelectors.makeSelectDataShoppingListDetailFilter()(state));
  // default is 8, we aren't support filter limit in shopping detail
  // requestUrl += `?current_page=${filter.curPage}&page_size=8`
  // page_size = 0 to get all items
  requestUrl += '?page_size=0'
  try {
    yield put(globalActions.showLoader())
    const res = yield call(request, requestUrl, {
      method: 'GET',
    })
    yield put(actions.saveListDetail(res))
  } catch (err) {
  } finally {
    yield put(globalActions.hideLoader())
  }
}

function* oDeleteShoppingList(action) {
  try {
    yield call(
      request,
      API_DELETE_SHOPPING_LIST_BY_LIST_ID.replace(
        ':listId',
        action.payload.listId
      ),
      {
        method: 'DELETE',
      }
    )
    yield showSuccess('This list has been removed')
    let shoppingListData = yield select((state) =>
      ShoppingListSelectors.makeSelectDataShoppingList()(state)
    )
    let newData = shoppingListData.filter(
      (item) => item.list_id !== action.payload.listId
    )
    yield put(actions.saveShoppingList(newData))
  } catch (err) {
  } finally {
  }
}

function* onDeleteItemFromShoppingList(action) {
  try {
    yield call(
      request,
      API_DELETE_ITEM_FROM_SHOPPING_LIST.replace(
        ':listId',
        action.payload.listId
      ).replace(':productId', action.payload.productId),
      {
        method: 'DELETE',
      }
    )
    yield put(actions.loadListDetail(action.payload))
    yield showSuccess('This item has been removed from the list')
  } catch (err) {
  } finally {
  }
}

function* onShareShoppingList(action) {
  const requestUrl = API_SHARE_MY_DATA_OF_LIST_BY_LIST_ID.replace(
    ':listId',
    action.payload.listId
  )
  const params = {
    share_data: [
      {
        customer_email: action.payload.email,
      },
    ],
  }
  try {
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ data: params }),
    })
    yield put(actions.loadShareShoppingList(action.payload.listId))
    yield showSuccess(`${action.payload.email} is added to shared list`)
  } catch (err) {
    showError('share this list error')
  } finally {
  }
}

function* onLoadShareShoppingList(action) {
  const requestUrl = API_GET_SHARE_MY_DATA_OF_LIST_BY_LIST_ID.replace(
    ':listId',
    action.payload
  )
  try {
    const res = yield call(request, requestUrl, {
      method: 'GET',
    })
    yield put(actions.saveShareShoppingList(res))
  } catch (err) {
  } finally {
  }
}

function* onAddSubscribeSaleForProduct(action) {
  const productId = get(action, 'payload.productId')
  const requestUrl = API_ADD_SUBSCRIBE_SALE_FOR_PRODUCT.replace(
    ':productId',
    productId
  )
  try {
    yield call(request, requestUrl, {
      method: 'POST',
    })
    yield put(actions.loadListDetail(action.payload))
    yield showSuccess('Item saved to price watch')
  } catch (err) {
  } finally {
  }
}

function* onDeleteSubscribeSaleForProduct(action) {
  const productId = get(action, 'payload.productId')
  const requestUrl = API_DELETE_SUBSCRIBE_SALE_FOR_PRODUCT.replace(
    ':productId',
    productId
  )
  try {
    yield call(request, requestUrl, {
      method: 'DELETE',
    })
    yield put(actions.loadListDetail(action.payload))
    yield showSuccess('Item removed from price watch')
  } catch (err) {
  } finally {
  }
}

function* onAddProductToShoppingList() {
  try {
    yield showSuccess('Item added to your list')
    yield put(hideModalShoppingList())
    // shoppingList and shopping list detail, we need reload data
    if (window.location.pathname.includes('/shopping-list')) {
      yield put(actions.loadShoppingList())
      if (window.location.pathname.includes('/shopping-list/list')) {
        const shoppingListDetail = yield select((state) =>
          ShoppingListSelectors.makeSelectDataShoppingListDetail()(state)
        )
        yield put(
          actions.loadListDetail({ listId: shoppingListDetail.list_id })
        )
      }
    }
  } catch (err) {
  } finally {
  }
}

function* onAddToMyList(action) {
  const listId = get(action, 'payload.listId')
  const token = get(action, 'payload.token')
  const data = get(action, 'data')
  const requestUrl = API_ADD_TO_MY_LIST.replace(':listId', listId).replace(
    ':token',
    token
  )
  try {
    yield put(actions.setCreateUpdateListLoading())
    yield call(request, requestUrl, {
      method: 'POST',
      body: JSON.stringify({ data }),
    })
    yield put(hideModalShoppingList())
    yield showSuccess('Added list to the new list')
  } catch (err) {
    const errorData = yield err.json()
    yield put(actions.requestCreateUpdateListError(errorData.message))
  } finally {
    yield put(actions.closeCreateUpdateListLoading())
  }
}

export default function* expSaga() {
  yield takeLatest(constant.REQUEST_CREATE_UPDATE_LIST, requestCreateUpdateList)
  yield takeLatest(constant.LOAD_SHOPPING_LIST, onLoadShoppingList)
  yield takeLatest(
    constant.LOAD_ALL_PRODUCT_IN_MY_LIST,
    onLoadAllProductInMyList
  )
  yield takeLatest(constant.LOAD_PAST_PURCHASE, onLoadPastPurchase)
  yield takeLatest(constant.LOAD_LIST_DETAIL, onLoadListDetail)
  yield takeLatest(constant.LOAD_DELETE_LIST, oDeleteShoppingList)
  yield takeLatest(
    constant.LOAD_DELETE_ITEM_FROM_LIST,
    onDeleteItemFromShoppingList
  )
  yield takeLatest(constant.SHARE_SHOPPING__LIST, onShareShoppingList)
  yield takeLatest(constant.LOAD_SHARE_SHOPPING__LIST, onLoadShareShoppingList)
  yield takeLatest(constant.ADD_SUBSCRIBE_SALE, onAddSubscribeSaleForProduct)
  yield takeLatest(
    constant.DELETE_SUBSCRIBE_SALE,
    onDeleteSubscribeSaleForProduct
  )
  yield takeLatest(
    constant.ADD_PRODUCT_TO_SHOPPING_LIST,
    onAddProductToShoppingList
  )
  yield takeLatest(constant.ADD_TO_MY_LIST, onAddToMyList)
}

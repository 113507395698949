/*
 *
 * Modals constants
 *
 */

// Show/Hide delivery location modal
export const SHOW_DELIVERY_LOCATION = 'landers/Modals/SHOW_DELIVERY_LOCATION'

export const HIDE_DELIVERY_LOCATION = 'landers/Modals/HIDE_DELIVERY_LOCATION'

// Show/Hide product detail modal
export const SHOW_PRODUCT_DETAIL = 'landers/Modals/SHOW_PRODUCT_DETAIL'
export const SHOW_PRODUCT_DETAIL_DONE =
  'landers/Modals/SHOW_PRODUCT_DETAIL_DONE'
export const HIDE_PRODUCT_DETAIL = 'landers/Modals/HIDE_PRODUCT_DETAIL'

// Show/Hide login modal
export const SHOW_LOGIN = 'landers/Modals/SHOW_LOGIN'
export const HIDE_LOGIN = 'landers/Modals/HIDE_LOGIN'

// Show/Hide forgot password modal
export const SHOW_FORGOT_PASSWORD = 'landers/Modals/SHOW_FORGOT_PASSWORD'
export const HIDE_FORGOT_PASSWORD = 'landers/Modals/HIDE_FORGOT_PASSWORD'

// Show/Hide add/update address modal
export const SHOW_ADDUPDATE_ADDRESS = 'landers/Modals/SHOW_ADDUPDATE_ADDRESS'

export const HIDE_ADDUPDATE_ADDRESS = 'landers/Modals/HIDE_ADDUPDATE_ADDRESS'

// Show/Hide invalid cart items modal
export const SHOW_INVALID_CARTITEMS = 'landers/Modals/SHOW_INVALID_CARTITEMS'

export const HIDE_INVALID_CARTITEMS = 'landers/Modals/HIDE_INVALID_CARTITEMS'

export const SHOW_REQUEST_ADD_MEMBERSHIP =
  'landers/Modals/SHOW_REQUEST_ADD_MEMBERSHIP'
export const HIDE_REQUEST_ADD_MEMBERSHIP =
  'landers/Modals/HIDE_REQUEST_ADD_MEMBERSHIP'

export const SHOW_CART_NOTIFICATION_MODAL =
  'landers/Modals/SHOW_CART_NOTIFICATION_MODAL'
export const HIDE_CART_NOTIFICATION_MODAL =
  'landers/Modals/HIDE_CART_NOTIFICATION_MODAL'

export const SHOW_REMOVE_ALL_CART_ITEMS =
  'landers/Modals/SHOW_REMOVE_ALL_CART_ITEMS'
export const HIDE_REMOVE_ALL_CART_ITEMS =
  'landers/Modals/HIDE_REMOVE_ALL_CART_ITEMS'

export const SHOW_REMOVE_CART_ITEMS = 'landers/Modals/SHOW_REMOVE_CART_ITEMS'
export const HIDE_REMOVE_CART_ITEMS = 'landers/Modals/HIDE_REMOVE_CART_ITEMS'

export const SHOW_ALERT = 'landers/Modals/SHOW_ALERT'
export const HIDE_ALERT = 'landers/Modals/HIDE_ALERT'

//Tab sign up
export const CHANGE_TAB_SIGN_UP = 'landers/Authentication/TabSignUp'

export const SHOW_MEMBERSHIP_MODAL = 'landers/Modals/SHOW_MEMBERSHIP_MODAL'
export const HIDE_MEMBERSHIP_MODAL = 'landers/Modals/HIDE_MEMBERSHIP_MODAL'

// Show SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED
export const SHOW_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED =
  'landers -> modals -> show Modal Inform Membership Is Expired'
export const HIDE_MODAL_INFORM_MEMBERSHIP_IS_EXPIRED =
  'landers -> modals -> hide Modal Inform Membership Is Expired'

// Show Shopping list
export const SHOW_SHARE_SHOPPING_LIST =
  'landers/Modals/SHOW_SHARE_SHOPPING_LIST'
export const SHOW_CREATE_SHOPPING_LIST =
  'landers/Modals/SHOW_CREATE_SHOPPING_LIST'
export const SHOW_EDIT_SHOPPING_LIST = 'landers/Modals/SHOW_EDIT_SHOPPING_LIST'
export const SHOW_ADD_PRODUCT_TO_SHOPPING_LIST =
  'landers/Modals/SHOW_ADD_PRODUCT_TO_SHOPPING_LIST'

export const HIDE_MODAL_SHOPPING_LIST =
  'landers/Modals/HIDE_MODAL_SHOPPING_LIST'

export const SHOW_MODAL_FAQ_SUCCESS = 'landers/Modals/SHOW_MODAL_FAQ_SUCCESS'
export const SHOW_MODAL_FAQ_FAILED = 'landers/Modals/SHOW_MODAL_FAQ_FAILED'
export const HIDE_MODAL_FAQ = 'landers/Modals/HIDE_MODAL_FAQ'

export const SHOW_PICKUP_CONFIRMATION =
  'landers/Modals/SHOW_PICKUP_CONFIRMATION'
export const HIDE_PICKUP_CONFIRMATION =
  'landers/Modals/HIDE_PICKUP_CONFIRMATION'

export const SHOW_APP_PROMOTION_MODAL =
  'landers/Modals/SHOW_APP_PROMOTION_MODAL'
export const HIDE_APP_PROMOTION_MODAL =
  'landers/Modals/HIDE_APP_PROMOTION_MODAL'

export const SHOW_SPLASH_AD_MODAL = 'landers/Modals/SHOW_SPLASH_AD_MODAL'
export const HIDE_SPLASH_AD_MODAL = 'landers/Modals/HIDE_SPLASH_AD_MODAL'

export const SHOW_NEW_MC_EXPIRED_MODAL =
  'landers/Modals/SHOW_NEW_MC_EXPIRED_MODAL'
export const HIDE_NEW_MC_EXPIRED_MODAL =
  'landers/Modals/HIDE_NEW_MC_EXPIRED_MODAL'

export const SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL =
  'landers/Modals/SHOW_NET_PROMOTERS_SCORE_SURVEY_MODAL'
export const HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL =
  'landers/Modals/HIDE_NET_PROMOTERS_SCORE_SURVEY_MODAL'

export const SHOW_VOUCHER_TERM_CONDITION_MODAL =
  'landers/Modals/SHOW_VOUCHER_TERM_CONDITION_MODAL'
export const HIDE_VOUCHER_TERM_CONDITION_MODAL =
  'landers/Modals/HIDE_VOUCHER_TERM_CONDITION_MODAL'

export const SHOW_MEMBERSHIP_CARD_MODAL =
  'landers/Modals/SHOW_MEMBERSHIP_CARD_MODAL'
export const HIDE_MEMBERSHIP_CARD_MODAL =
  'landers/Modals/HIDE_MEMBERSHIP_CARD_MODAL'

export const SHOW_DELIVERY_ADDRESS_MODAL =
  'landers/Modals/SHOW_DELIVERY_ADDRESS_MODAL'
export const HIDE_DELIVERY_ADDRESS_MODAL =
  'landers/Modals/HIDE_DELIVERY_ADDRESS_MODAL'

export const SHOW_SUBSCRIBE_SUCCESS_MODAL =
  'landers/Modals/SHOW_SUBSCRIBE_SUCCESS_MODAL'
export const HIDE_SUBSCRIBE_SUCCESS_MODAL =
  'landers/Modals/HIDE_SUBSCRIBE_SUCCESS_MODAL'

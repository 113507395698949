import { delay, call, put, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import * as constants from './constants'

import {
  saveProductDetail,
  loadRelatedProduct,
} from 'containers/ProductDetail/actions'
import * as AuthenticationActions from 'containers/Authentication/actions'
import { showLoader, hideLoader } from 'containers/Landers/actions'

import productDetailServices from 'services/productDetail'

function* onShowCartNotificationModal(action) {
  yield delay(3000)
  yield put(actions.hideCartNotificationModal())
}

function* onLoadProductDetail(action) {
  try {
    yield put(showLoader())
    const data = yield call(
      [productDetailServices, 'getProductDetail'],
      action.payload
    )
    yield put(loadRelatedProduct(data.sku))
    yield put(saveProductDetail(data))
    yield put(actions.showProductDetailModalDone())
    yield put(hideLoader())
  } catch (err) {
    yield put(actions.hideProductDetailModal())
    yield put(hideLoader())
  }
}

function* onHideForgotPassword(action) {
  yield put(AuthenticationActions.clearDataResetPassword())
}

export default function* modalsSaga() {
  yield takeLatest(
    constants.SHOW_CART_NOTIFICATION_MODAL,
    onShowCartNotificationModal
  )
  yield takeLatest(constants.SHOW_PRODUCT_DETAIL, onLoadProductDetail)
  yield takeLatest(constants.HIDE_FORGOT_PASSWORD, onHideForgotPassword)
}

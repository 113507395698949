import produce from 'immer'
import { get } from 'lodash'
import * as constants from './constants'

export const initialState = {
  resetPasswordData: {
    success: false,
    error: false,
  },
  inValidSignUp: '',
  checkMembership: {
    success: false,
    error: false,
    message: '',
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      case constants.SIGN_UP:
        newState.inValidSignUp = ''
        break
      case constants.SIGN_UP_SUCCESS:
        newState.token = action.payload
        newState.inValidSignUp = ''
        break
      case constants.SIGN_UP_FAILED:
        newState.inValidSignUp = get(action, 'error.message', '')
        break
      case constants.CHECK_MEMBERSHIP:
        break
      case constants.CHECK_MEMBERSHIP_SUCCESS:
        newState.checkMembership = {
          success: true,
          error: false,
          message: '',
        }
        break
      case constants.CHECK_MEMBERSHIP_FAILED:
        newState.checkMembership = {
          success: false,
          error: true,
          message: get(action, 'error', ''),
        }
        break
      case constants.RESET_PASSWORD:
        break
      case constants.RESET_PASSWORD_SUCCESS:
        newState.resetPasswordData = {
          success: action.payload,
          error: false,
        }
        break
      case constants.RESET_PASSWORD_FAILED:
        newState.resetPasswordData = {
          success: false,
          error: action.error,
          link: action.error,
        }
        break
      case constants.CLEAR_DATA_FORGOT_PASSWORD:
        newState.resetPasswordData = {
          success: false,
          error: false,
        }
        break
      default:
        break
    }
  })

export default reducer
